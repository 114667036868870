import qs from "qs";

export function getStrapiURL() {
  return `${
    process.env.REACT_APP_STRAPIBACKEND_API_URL || "http://localhost:1337/api"
  }`;
}

export function getStrapiBaseURL() {
  return `${
    process.env.REACT_APP_STRAPIBACKEND_URL || "http://localhost:1337"
  }`;
}
// eslint-disable-next-line
async function fetchAPI(path, authRequired = false, urlParamsObject = {}) {
  const strapiURL = getStrapiURL();
  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const API_TOKEN = process.env.API_TOKEN || "";

  if (authRequired) {
    defaultOptions.headers.Authorization = `Bearer ${API_TOKEN}`;
  }

  const mergedOptions = {
    ...defaultOptions,
  };

  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${strapiURL}/${path}${
    queryString ? `?${queryString}` : ""
  }`;

  const response = await fetch(requestUrl, mergedOptions);
  // eslint-disable-next-line
  const { data, error } = await response.json();

  if (!response.ok) {
    throw new Error("An error ocurred, please try again");
  }
  return data;
}

export async function getAdminSettings(path, token) {
  const strapiURL = getStrapiURL();
  console.log("getAdminSettings => strapiURL: ", strapiURL);
  console.log("token: ", token);
  try {
    const response = await fetch(`${strapiURL}${path}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return { message: "success", data };
    } else return { message: "failure", data: {} };
  } catch (error) {
    throw new Error(error);
  }
}

//BUG: Formatting data for PUT https://forum.strapi.io/t/solved-new-version-axios-post-request-responces-400-bad-request/16081
// see format below starting with data: {key:value, key:value, }
export async function updateAdminSettings(path, token, data) {
  const strapiURL = getStrapiURL();
  console.log(`updateAdminSettings => strapiURL: ${strapiURL}${path}`);
  console.log("updateAdminSettings => update data: ", data);
  console.log(" CronJobPlatformActive =>  ", data.CronJobPlatformActive);
  console.info(" CronJobType => ", data.CronJobType);
  const CronJobPlatformActive = data.CronJobPlatformActive;
  const CronJobType = data.CronJobType;
  try {
    const response = await fetch(`${strapiURL}${path}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          CronJobPlatformActive: CronJobPlatformActive,
          CronJobType: CronJobType,
        },
      }),
    });

    if (response.ok) {
      console.log("response was OK");
      const data = await response.json();
      return { message: "success", data };
    } else
      return {
        message: "failure",
        data: {},
      };
  } catch (error) {
    console.log("response was not OK");
    throw new Error(error);
  }
}

// Read the Articles from the CMS system using graphql syntax/graphql subsystem
export async function getArticleBySlug(slug) {
  const strapiURL =
    process.env.REACT_APP_STRAPIBACKEND_URL || "http://localhost:1337";
  //const strapiURL =
  //    "https://scottblogsitebackend-staging.herokuapp.com/graphql";

  console.log("strapiURL", strapiURL);
  const articleRes = await fetch(`${strapiURL}/graphql`, {
    //const articleRes = await fetch(`${strapiURL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `
                query GetArticle(
                    $slug: String!
                ) {
                    articles(
                        filters: {slug: {eq: $slug}}
                    ) {
                        data {
                            attributes {
                                published_date
                                title
                                content
                                description
                                author {
                                  data {
                                    attributes {
                                      name
                                    }
                                  }
                                }
                                category {
                                 data {
                                   attributes {
                                     name
                                     slug
                                   }
                                 }
                                }
                                image {
                                  data {
                                   attributes {
                                    height
                                    width
                                    formats
                                    alternativeText
                                    url
                                   }
                                  }
                                 }
                               }
                        }
                    }
                }
                `,
      variables: {
        slug,
      },
    }),
  });

  const articleData = await articleRes.json();

  console.log("ARTICLE DATA WE GOT BACK FROM GRAPHQL: ");
  console.log({ articleData });

  if (
    articleData.data.articles === null ||
    articleData.data.articles.length === 0
  ) {
    return null;
  }
  return articleData.data.articles.data[0];
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SVG } from "../img/acronym-grid-optimized.svg";
import gsap from "gsap";
import * as Utilities from "./AnimationUtilities";

const ACRONYMS = [
  "C#",
  "VB",
  "SQL",
  "K8S",
  "IaC",
  "CSS",
  "JS",
  "HTM",
  "XML",
  "ASP",
  "MVC",
  "Git",
  "API",
  "JWT",
  "TDD",
  "EF",
  "DI",
  "Web",
  "IIS",
  "php",
  "png",
  "jpg",
  "svg",
  "UI",
  "UX",
  "CI",
  "CD",
  "VM",
  "AI",
  "NFS",
  "DNS",
  "WAF",
  "SSH",
  "RDP",
  "AVD",
  "ORM",
  "PAT",
  "SMS",
  "TFA",
];

const SCALE_IN_DELAY = 2.5;
const UPDATE_TEXT_DELAY = 0.66;
const BLINK_DELAY = 0.5;
let BLINKED = false;
let TEXT_COLORS = ["#ffcc80", "orange", "#ef6c00"];

const useStyles = makeStyles((theme) => ({
  animationContainer: {
    width: "100%",
    height: "100%",
    minWidth: "500px",
    minHeight: "500px",
    [theme.breakpoints.only("xs")]: {
      minWidth: "400px",
      minHeight: "400px",
    },
    visibility: "hidden",
  },
  svg: {
    width: "80%",
    maxWidth: "500px",
    height: "auto",
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    zIndex: 100,
    "& *": {
      fontFamily: `'Roboto Mono', monospace !important`,
    },
    "& #cells": {
      position: "relative",
    },
    "& .grid-cell": {
      position: "relative",
    },
    "& .cell-text": {
      position: "absolute",
      transformOrigin: "50% 50% !important",
    },
    "& .cell-background": {
      visibility: "hidden",
    },
    "& #blocksTwo path": {
      visibility: "hidden",
    },
    "& #background": {
      visibility: "hidden",
    },
  },
}));

function AcronymGrid(props) {
  const animationContainerRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const animationContainer = animationContainerRef.current;
    const svg = svgRef.current;

    // SETUP
    Utilities.centerElement(svg);

    // reveal elements
    const mainTL = gsap.timeline();
    mainTL.set(svg, { autoAlpha: 1 });
    mainTL.to(animationContainer, { autoAlpha: 1, duration: 0.1 });
    mainTL.add(initialReveal(svg), 0.3);

    // start animation loop
    mainTL.call(updateText, [svg, true]);
  }, []);

  const classes = useStyles();

  return (
    <div ref={animationContainerRef} className={classes.animationContainer}>
      <SVG ref={svgRef} className={classes.svg} />
    </div>
  );
}

// animation functions
function initialReveal(svg) {
  const tl = gsap.timeline();
  const duration = 1.5;

  tl.from(
    svg,
    {
      scale: 0,
      duration: duration,
      transformOrigin: "50% 50%",
      ease: "back.out(1.7)",
    },
    0
  );

  tl.from(
    svg,
    {
      rotation: -360,
      duration: duration,
      transformOrigin: "50% 50%",
      ease: "back.out(0.6)",
    },
    0
  );

  return tl;
}

function scaleIn(svg) {
  const delay = SCALE_IN_DELAY;
  const blinkOrNot = gsap.utils.random([1, 2, 3, 4]) === 1 ? true : false;

  if (blinkOrNot && !BLINKED) {
    gsap.delayedCall(delay, blink, [svg]);
  } else {
    const blocks = svg.querySelectorAll("#blocks path");
    const tl = gsap.timeline();

    const from = gsap.utils.random(["start", "center", "random", "end"]);
    const axis = gsap.utils.random(["x", "y", null]);

    const originRoll = gsap.utils.random([0, 1, 2, 3, 4]);
    const transformOrigin = [
      "50% 50%",
      "0% 50%",
      "100% 50%",
      "50% 0%",
      "50% 100%",
    ];
    let scaleY = 1;
    let scaleX = 1;

    switch (originRoll) {
      case 0:
        scaleX = 0;
        scaleY = 0;
        break;
      case 1:
        scaleX = 0;
        scaleY = 1;
        break;
      case 2:
        scaleX = 0;
        scaleY = 1;
        break;
      case 3:
        scaleX = 1;
        scaleY = 0;
        break;
      case 4:
        scaleX = 1;
        scaleY = 0;
        break;
      default:
        scaleX = 0;
        scaleY = 0;
    }

    tl.set(blocks, { autoAlpha: 1 });

    tl.from(
      blocks,
      {
        scaleX: scaleX,
        scaleY: scaleY,
        transformOrigin: transformOrigin[originRoll],
        duration: 0.35,
        stagger: {
          grid: [3, 3],
          from: from,
          axis: axis,
          each: 0.125,
        },
      },
      delay
    );

    BLINKED = false;
    tl.call(updateText, [svg, true]);
  }
}

function scaleOut(svg) {
  const blocks = svg.querySelectorAll("#blocks path");
  const tl = gsap.timeline();

  const from = gsap.utils.random(["start", "center", "random", "end"]);
  const axis = gsap.utils.random(["x", "y", null]);

  const originRoll = gsap.utils.random([0, 1, 2, 3]);
  const transformOrigin = ["50% 100%", "50% 0%", "100% 50%", "0% 50%"];
  let scaleY = 1;
  let scaleX = 1;
  if (originRoll < 2) {
    scaleY = 0;
    scaleX = 1;
  } else {
    scaleY = 1;
    scaleX = 0;
  }

  tl.to(blocks, {
    scaleY: scaleY,
    scaleX: scaleX,
    transformOrigin: transformOrigin[originRoll],
    duration: 0.35,
    stagger: {
      grid: [3, 3],
      from: from,
      axis: axis,
      each: 0.125,
    },
  });

  // reset
  blocks.forEach((block) => {
    tl.set(block, {
      autoAlpha: 0,
      scaleY: 1,
      scaleX: 1,
      transformOrigin: transformOrigin[originRoll],
    });
  });

  tl.call(scaleIn, [svg]);
}

function blink(svg) {
  // scale in from top and bottom, update text, scale out to top and bottom
  BLINKED = true;
  const topBlocks = svg.querySelectorAll("#blocks path");
  const bottomBlocks = svg.querySelectorAll("#blocksTwo path");

  // setup
  const tl = gsap.timeline({ onComplete: scaleIn, onCompleteParams: [svg] });
  tl.set(topBlocks, {
    scaleX: 1,
    scaleY: 0,
    transformOrigin: "50% 0%",
    autoAlpha: 1,
  });
  tl.set(bottomBlocks, {
    scaleX: 1,
    scaleY: 0,
    transformOrigin: "50% 100%",
    autoAlpha: 1,
  });
  const from = gsap.utils.random(["start", "center", "random", "end"]);
  const axis = gsap.utils.random(["x", "y", null]);
  const closeEyesDuration = 0.5;
  const openEyesDuration = 0.5;

  // close eyes
  tl.add("close eyes", SCALE_IN_DELAY);
  tl.to(
    topBlocks,
    {
      scaleX: 1,
      scaleY: 1,
      transformOrigin: "50% 0%",
      duration: closeEyesDuration,
      stagger: {
        grid: [3, 3],
        from: from,
        axis: axis,
        each: 0.01,
      },
    },
    "close eyes"
  );
  tl.to(
    bottomBlocks,
    {
      scaleX: 1,
      scaleY: 1,
      transformOrigin: "50% 100%",
      duration: closeEyesDuration,
      stagger: {
        grid: [3, 3],
        from: from,
        axis: axis,
        each: 0.01,
      },
    },
    "close eyes"
  );

  // update text
  tl.call(updateText, [svg, false]);

  // open eyes
  tl.add("open eyes", "+=" + BLINK_DELAY);
  tl.to(
    topBlocks,
    {
      scaleX: 1,
      scaleY: 0,
      transformOrigin: "50% 0%",
      duration: openEyesDuration,
      stagger: {
        grid: [3, 3],
        from: from,
        axis: axis,
      },
    },
    "open eyes"
  );
  tl.to(
    bottomBlocks,
    {
      scaleX: 1,
      scaleY: 0,
      transformOrigin: "50% 100%",
      duration: openEyesDuration,
      stagger: {
        grid: [3, 3],
        from: from,
        axis: axis,
      },
    },
    "open eyes"
  );

  // reset blocks
  topBlocks.forEach((block) => {
    tl.set(block, {
      autoAlpha: 0,
      scaleY: 1,
      scaleX: 1,
      transformOrigin: "50% 0%",
    });
  });
  bottomBlocks.forEach((block) => {
    tl.set(block, {
      autoAlpha: 0,
      scaleY: 1,
      scaleX: 1,
      transformOrigin: "50% 100%",
    });
  });
}

// utilities
function updateText(svg, callback) {
  const acronyms = ACRONYMS;
  let random = acronyms.sort(() => 0.5 - Math.random()).slice(0, 3);
  let newStrArray = [];
  random.forEach((string) => {
    let str = (string + "   ").slice(0, 3);
    newStrArray.push(str);
  });
  let newString = newStrArray.join("");

  const text = svg.querySelectorAll(".cell-text");
  gsap.utils
    .toArray(text)
    .reverse()
    .forEach((char, i) => {
      char.textContent = newString[i].toUpperCase();
      char.style.fill = updateTextColor(i);
    });
  TEXT_COLORS = Utilities.sattoloShuffleCopy(TEXT_COLORS);

  if (callback) {
    gsap.delayedCall(UPDATE_TEXT_DELAY, scaleOut, [svg]);
  }
}

function updateTextColor(index) {
  let color;

  if (index < 3) {
    color = TEXT_COLORS[0];
  } else if (index < 6) {
    color = TEXT_COLORS[1];
  } else {
    color = TEXT_COLORS[2];
  }

  return color;
}

export default AcronymGrid;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Link, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { getAdminSettings, updateAdminSettings } from "../../utils/api-helpers";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  formFormat: { fontSize: "0.95rem", padding: "0.5em" },
  formFields: { margin: "0.2em", width: "175px" },
  formErrors: { color: "red", fontSize: "0.7rem" },
  formSubmitButton: {
    marginTop: "25px",
    marginLeft: "5px",
    marginBottom: "35px",
  },
  headerImage: {
    textAlign: "center",
  },
}));
///////////////////////////////////// NOTES ///////////////////////////////////////
// This is the Admin Tools form to allow a user to update some Admin Settings contained in the CMS.
//    - The user needs to be loggged inbefore attempting to use this page. This page may be hidden in the React app/menus.
function AdminToolsPageForm(props) {
  toast.configure();

  const [loading, setLoading] = useState(true);
  const [strapiData, setStrapiData] = useState(null);
  // eslint-disable-next-line
  const [strapiError, setStrapiError] = useState(null);

  // BUG: https://stackoverflow.com/questions/45592277/radio-button-not-working  dont use defaultValues and defaultChecked??
  // https://surajsharma.net/blog/react-handle-radio-buttons
  const { register, handleSubmit } = useForm(); // Custom hook to mange the entire Admin Tools form.

  // eslint-disable-next-line
  const navigate = useNavigate();
  const token = localStorage.getItem("portfolio-token");

  // updateAdminSettings will call our Strapi backend to attempt to update the admin tools data
  // note: only logged in Users with isAdmin should access this page
  useEffect(() => {
    const strapiURL =
      process.env.REACT_APP_STRAPIBACKEND_API_URL ||
      "http://localhost:1337/api";
    const strapiUsersMeMethod =
      process.env.REACT_APP_STRAPIBACKEND_API_ADMINTOOLSSETTING ||
      "/admin-tools-setting";
    const strapiURLRequest = strapiURL + strapiUsersMeMethod;

    //fetch(`http://localhost:1337/api/admin-tools-setting`, {
    fetch(strapiURLRequest, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        const { error, data } = await response.json();
        if (!response.ok) {
          if (error.message) {
            setStrapiError({ message: error.message });
          } else {
            setStrapiError({ message: "Internal Server Error" });
          }
          setStrapiData(null);
          return;
        }
        setStrapiError(null);
        return data;
      })
      .then((data) => {
        setStrapiData(data?.attributes);
        setLoading(false);
      })
      .catch((error) => {
        setStrapiError({ message: "Internal Server Error" });
        setLoading(false);
        setStrapiData(null);
      });
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  const onSubmit = async (formData) => {
    setLoading(true);
    const strapiURL =
      process.env.REACT_APP_STRAPIBACKEND_API_URL ||
      "http://localhost:1337/api";
    const strapiUsersMeMethod =
      process.env.REACT_APP_STRAPIBACKEND_API_ADMINTOOLSSETTING ||
      "/admin-tools-setting";
    const strapiURLRequest = strapiURL + strapiUsersMeMethod;

    const response = await fetch(
      strapiURLRequest,
      //"http://localhost:1337/api/admin-tools-setting",
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            CronJobPlatformActive: formData.CronJobPlatformActive,
            CronJobType: formData.CronJobType,
          },
        }),
      }
    );

    // eslint-disable-next-line
    const { error, message, data } = await response.json();

    if (response.ok) {
      console.log(data.attributes);
      setStrapiData(data.attributes);
      setLoading(false);
      setStrapiError(null);
    } else {
      setStrapiError({ message: "error" });
      setLoading(false);
    }
  };

  console.log(loading);
  console.log(strapiData);
  console.log(strapiError);

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {" "}
      {!loading && strapiData !== null && !strapiError ? (
        <Grid container item xs={11}>
          <Grid className={classes.hero} item xs={12} md={7}>
            <Typography
              variant="h1"
              className={classes.headerH1}
              align="center"
            >
              Admin Tool Settings
            </Typography>
            <Typography className={classes.subHeader}>
              Below is a list of the <u>Admin Tool Settings</u> for our system.
              In the future we are planning to add more Admin Settings to this
              Admin-only page. In the meantime feel free to go back to the{" "}
              <Link href="/" rel="noopener" color="secondary">
                Home Page
              </Link>{" "}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.formFormat}
            >
              <label>
                <i>
                  <u>OPTION 1 :</u>
                </i>{" "}
                {strapiData.HtmlLabelInfo}
              </label>
              <br />
              <label htmlFor="field-enablejob">
                <input
                  defaultChecked={strapiData.CronJobPlatformActive === true}
                  {...register("CronJobPlatformActive")}
                  type="radio"
                  name="CronJobPlatformActive"
                  value={true}
                  id="field-enablejob"
                />
                Enable Job{" "}
              </label>
              <label htmlFor="field-disablejob">
                <input
                  defaultChecked={strapiData.CronJobPlatformActive === false}
                  {...register("CronJobPlatformActive")}
                  type="radio"
                  name="CronJobPlatformActive"
                  value={false}
                  id="field-disablejob"
                />
                Disable Job
              </label>
              <br />
              <br />
              <label>
                <i>
                  <u>OPTION 2 :</u>{" "}
                </i>
                {strapiData.HtmlLabelInfo2}
              </label>
              <br />
              <label htmlFor="field-cronjobtype">
                <input
                  defaultChecked={strapiData.CronJobType === "cronjob"}
                  {...register("CronJobType")}
                  type="radio"
                  name="CronJobType"
                  value={"cronjob"}
                  id="field-cronjobtype"
                />
                CMS Cron Job{" "}
              </label>
              <label htmlFor="field-herokujobtype">
                <input
                  defaultChecked={strapiData.CronJobType === "herokujob"}
                  {...register("CronJobType")}
                  type="radio"
                  name="CronJobType"
                  value={"herokujob"}
                  id="field-herokujobtype"
                />
                Heroku Scheduled Job
              </label>
              <br />
              <div>
                <Button
                  className={classes.formSubmitButton}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  <Typography
                    style={{
                      textAlign: "right",
                      minWidth: "170px",
                      textTransform: "none",
                    }}
                  >
                    Save Settings {` `}
                    <FontAwesomeIcon icon="fa-solid fa-address-card" />
                  </Typography>
                </Button>
              </div>
              <div>
                <br />
                Notes about these admin settings:
                <hr />
                <i>{strapiData.NoteDescription}</i>{" "}
                {strapiData.BottomofPageNotes}
                <br />
                <br />
                <i>{strapiData.NoteDescription2}</i>{" "}
                {strapiData.BottomofPageNotes2}
              </div>
            </form>
          </Grid>
          <Grid className={classes.headerRight} item xs={12} md={5}>
            <div className={classes.headerImage}>
              <img
                src="https://i.picsum.photos/id/2/400/300.jpg?grayscale&hmac=oV_ybjwxp4S7HHkw6y0u-oWRpcIlG9NWsJzZ1eGMCL4"
                alt="login"
              />
            </div>
          </Grid>
        </Grid>
      ) : strapiError ? (
        <div>{strapiError.message}</div>
      ) : (
        <div>Loading...</div>
      )}
    </Grid>
  );
}

export default AdminToolsPageForm;

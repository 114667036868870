import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  // eslint-disable-next-line
  faAngleUp,
  // eslint-disable-next-line
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  Typography,
  Menu,
  // eslint-disable-next-line
  Collapse,
  useMediaQuery,
} from "@material-ui/core";
// eslint-disable-next-line
import MuiLink from "@material-ui/core/Link";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import { useLocation, useNavigate } from "react-router-dom";
import SiteMap from "./SiteMap";
import { CurrentUserContext } from "./userContext";
import MyLink from "./components/MyLink";

const MenuItem = withStyles({
  root: {
    justifyContent: "flex-end",
  },
})(MuiMenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.navbar,
  },
  siteTitle: {},
  navbarDisplayFlex: {
    display: `flex`,
    alignItems: "center",
    justifyContent: `space-between`,
  },
  signInButton: {
    color: theme.palette.colors.lightOrange,
    borderColor: theme.palette.colors.lightOrange,
    "&:hover": {
      backgroundColor: theme.palette.colors.lightOrange,
      color: theme.palette.colors.black,
    },
  },
  iconButton: {
    height: "37px",
    width: "20px",
    border: "0.5px solid rgba(255, 255, 255, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
    "& svg": {
      width: "55%",
      height: "auto",
    },
    "& path": {
      color: theme.palette.colors.lightGray,
    },
    borderRadius: "5px",
  },
  siteNavigationMenu: {
    zIndex: "900 !important",
    "& .MuiMenu-paper": {
      backgroundColor: theme.palette.background.siteNavigationMenu,
      marginTop: "4px",
    },
    "& li, li a": {
      fontWeight: "700",
      fontSize: "18px",
      color: theme.palette.colors.white,
      textDecoration: "none !important",
    },
    "& li:hover, li a:hover": {
      color: theme.palette.links.color,
      backgroundColor: "initial",
    },
  },
  siteNavigationLink: {},
  nestedListIcon: {
    paddingLeft: theme.spacing(1),
  },
  nestedListItem: {
    paddingRight: theme.spacing(4),
    fontSize: "1.2em !important",
  },
  placeholderNavBar: {
    visibility: "hidden",
  },
}));

function Navbar(props) {
  const { currentUser, handleLogout } = React.useContext(CurrentUserContext);

  console.info(currentUser);
  console.info(
    "NAVBAR.JS: We just ran Navbar function again, and our currentUser data is above: "
  );
  let navigationLinks = SiteMap();
  navigationLinks.pop();

  // I want to filter out the navigation links contained in the SiteMap.js file
  // that I dont want to display in the hamburger menu in the Navbar.
  // Those are pages that have a property of hideThisinNavBar = true
  // TODO: add null/undefined check for a missing hideThisinNavBar in one of the pages
  let navigationLinksFiltered = navigationLinks.filter(
    (thelink) => thelink.hideThisinNavBar !== true
  );

  const location = useLocation();

  let pageTitle = "ScottDevOps.com";
  navigationLinksFiltered.forEach((page) => {
    if (page.path === location.pathname && page.title) {
      pageTitle = page.title;
    }
  });

  const [hamburgerAnchorEl, setHamburgerAnchorEl] = React.useState(null);
  const [nestedListTryBuyOpen, setNestedListTryBuyOpen] = React.useState(false);
  const [nestedListBuyOpen, setNestedListBuyOpen] = React.useState(false);

  const handleHamburgerClick = (event) => {
    if (hamburgerAnchorEl) {
      setHamburgerAnchorEl(null);
    } else {
      setHamburgerAnchorEl(event.currentTarget);
    }
  };
  const closeSiteNavigationMenu = () => {
    setHamburgerAnchorEl(null);
  };
  // eslint-disable-next-line
  const handleNestedListTryBuyClick = () => {
    setNestedListTryBuyOpen(!nestedListTryBuyOpen);
  };
  // eslint-disable-next-line
  const handleNestedListBuyClick = () => {
    setNestedListBuyOpen(!nestedListBuyOpen);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {}, []);

  const classes = useStyles();
  const navigate = useNavigate();

  const loginOrLogout = (currentUser, handleLogout) => {
    if (currentUser) {
      return (
        <Button
          onClick={handleLogout}
          className={classes.signInButton}
          aria-label="log out button"
          variant="outlined"
          // color={theme.palette.colors.lightOrange}
        >
          Logout
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => navigate("/login")}
          className={classes.signInButton}
          aria-label="login button"
          variant="outlined"
          // color={theme.palette.colors.lightOrange}
        >
          Login
        </Button>
      );
    }
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Container maxWidth="lg" className={classes.navbarDisplayFlex}>
            {loginOrLogout(currentUser, handleLogout)}
            <Typography
              variant="h6"
              align="center"
              className={classes.siteTitle}
            >
              <Box component="span" display={{ xs: "none", md: "block" }}>
                {pageTitle}
              </Box>
            </Typography>
            <Button
              className={[classes.iconButton].join(" ")}
              ari-label="toggle site navigation menu"
              aria-controls="site-navigation-menu"
              aria-haspopup="true"
              onClick={handleHamburgerClick}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
      {/* this hack is one of the actual suggestions for a sticky header that won't overlay the page content */}
      <Toolbar className={classes.placeholderNavBar}></Toolbar>

      {/* the menu */}
      <Menu
        id="site-navigation-menu"
        anchorEl={hamburgerAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(hamburgerAnchorEl)}
        onClose={closeSiteNavigationMenu}
        disableScrollLock={!smallScreen}
        keepMounted
        TransitionComponent={Slide}
        className={classes.siteNavigationMenu}
      >
        {navigationLinksFiltered.map((link) => {
          const internalLink = () => {
            if (link.adminOnly) {
              if (!currentUser?.isAdmin || link.hideThisInAdmin) {
                return <></>;
              }
              return (
                <MyLink
                  color="primary"
                  path={link.path}
                  linkText={link.linkText}
                  currentUser={currentUser}
                  protectThis={link.protectThis}
                />
              );
            } else {
              return (
                <MyLink
                  color="primary"
                  path={link.path}
                  linkText={link.linkText}
                  currentUser={currentUser}
                  protectThis={link.protectThis}
                />
              );
            }
            // <Link
            //   className={classes.siteNavigationLink}
            //   to={link.path}
            //   key={link.path}
            // >
            //   {link.linkText}
            // </Link>
          };
          const externalLink = (
            <a
              className={classes.siteNavigation}
              href={link.path}
              target="_blank"
              rel="noopener noreferrer"
              key={link.path}
            >
              {link.linkText}
            </a>
          );
          const newLink =
            link.externalLink === true ? externalLink : internalLink();
          return (
            <MenuItem
              onClick={closeSiteNavigationMenu}
              key={"key-" + link.linkText}
            >
              {newLink}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default Navbar;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ResumeHeader from "./ResumeHeader";
import ResumeGrid from "./ResumeGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px", // space for fixed footer
  },
}));

function ResumePage(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ResumeHeader />
      <ResumeGrid />
    </div>
  );
}

export default ResumePage;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProfilePageForm from "./ProfilePageForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px", // space for fixed footer
  },
}));

function ProfilePage(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ProfilePageForm />
    </div>
  );
}

export default ProfilePage;

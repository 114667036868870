import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";
import GSAPSliderMySoftwareApps from "./GSAPSliderMySoftwareApps";
import SliderCaptionMySoftwareApps from "./SliderCaptionMySoftwareApps";
import LightBoxCaptionMySoftwareApps from "./LightBoxCaptionMySoftwareApps";
import SectionTwoMySoftwareApps from "./SectionTwoMySoftwareApps";
import SectionThreeMySoftwareApps from "./SectionThreeMySoftwareApps";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#444",
    overflowX: "hidden",
  },
  sliderContainer: {
    width: "100vw",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
    [theme.breakpoints.only("md")]: {
      height: "600px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "600px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "700px",
    },
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#111",
  },
}));

function SliderMySoftwareAppsPage(props) {
  const slides = [
    {
      img: "https://picsum.photos/id/1019/800/600",
      imgAlt: "the first slide",
      caption: (
        <SliderCaptionMySoftwareApps
          headline="Help Desk App"
          text="image caption. this is a caption My Help Desk App."
        />
      ),
      lightBoxCaption: (
        <LightBoxCaptionMySoftwareApps
          headline="LightBox Caption"
          text="All about My Help Desk Apps. This is fullscreen caption text. This uses the LightBoxCaptionMySoftwareApps component, and is shown when the slider enters fullscreen view (commonly referred to as a lightbox). this area will scroll when its content overflows. there can be lots and lots and lots and lots and lots of content in here. extra content from Proteus: Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy. Bald he was and a millionaire, maestro di color che sanno. Limit of the diaphane in. Why in? Diaphane, adiaphane. If you can put your five fingers through it, it is a gate, if not a door. Shut your eyes and see."
        />
      ),
    },
    {
      img: "https://picsum.photos/id/1021/800/600",
      imgAlt: "the second slide",
      caption: (
        <SliderCaptionMySoftwareApps
          headline="My App 2"
          text="this is a caption for slide 2. this caption is longer than the others. see if we can push this on to multiple lines."
        />
      ),
      lightBoxCaption: (
        <LightBoxCaptionMySoftwareApps>
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Typography variant="h4" gutterBottom>
              LightBox Caption 2
            </Typography>
            <Typography align="left" paragraph>
              this is a LightBox Caption with child components. the other
              LightBox Captions use props to print a headline and text. this
              shows how to use children as props to include more customized
              content. use this method to include HTML like <b>bold text</b>{" "}
              <i>italic text</i> or{" "}
              <Link
                href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a"
                target="_blank"
                rel="noopener"
              >
                links
              </Link>
              .
            </Typography>
            <Typography variant="h5" gutterBottom>
              placeholder text from{" "}
              <Link
                href="http://m.joyceproject.com/chapters/proteus.html"
                target="_blank"
                rel="noopener"
              >
                Proteus
              </Link>
            </Typography>
            <Typography align="left" paragraph>
              Ineluctable modality of the visible: at least that if no more,
              thought through my eyes. Signatures of all things I am here to
              read, seaspawn and seawrack, the nearing tide, that rusty boot.
              Snotgreen, bluesilver, rust: coloured signs. Limits of the
              diaphane. But he adds: in bodies. Then he was aware of them bodies
              before of them coloured. How? By knocking his sconce against them,
              sure. Go easy. Bald he was and a millionaire, maestro di color che
              sanno. Limit of the diaphane in. Why in? Diaphane, adiaphane. If
              you can put your five fingers through it, it is a gate, if not a
              door. Shut your eyes and see.
            </Typography>
            <Typography align="left" paragraph>
              Stephen closed his eyes to hear his boots crush crackling wrack
              and shells. You are walking through it howsomever. I am, a stride
              at a time. A very short space of time through very short times of
              space. Five, six: the nacheinander. Exactly: and that is the
              ineluctable modality of the audible. Open your eyes. No. Jesus! If
              I fell over a cliff that beetles o'er his base, fell through the
              nebeneinander ineluctably! I am getting on nicely in the dark. My
              ash sword hangs at my side. Tap with it: they do. My two feet in
              his boots are at the ends of his legs, nebeneinander. Sounds
              solid: made by the mallet of Los Demiurgos. Am I walking into
              eternity along Sandymount strand? Crush, crack, crick, crick. Wild
              sea money. Dominie Deasy kens them a'.
            </Typography>
          </Grid>
        </LightBoxCaptionMySoftwareApps>
      ),
    },
    {
      img: "https://picsum.photos/id/1041/800/600",
      imgAlt: "the third slide",
      caption: (
        <SliderCaptionMySoftwareApps text='just a caption, no headline. just set the "text" prop and ignore the "headline" prop.' />
      ),
      lightBoxCaption: (
        <LightBoxCaptionMySoftwareApps
          headline="LightBox Caption 3"
          text="fullscreen caption text. this uses the LightBoxCaptionMySoftwareApps component, and is shown when the slider enters fullscreen view (commonly referred to as a lightbox). this area will scroll when its content overflows. there can be lots and lots and lots and lots and lots of content in here. extra content from Proteus: Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy. Bald he was and a millionaire, maestro di color che sanno. Limit of the diaphane in. Why in? Diaphane, adiaphane. If you can put your five fingers through it, it is a gate, if not a door. Shut your eyes and see."
        />
      ),
    },
    {
      img: "https://picsum.photos/id/1015/800/600",
      imgAlt: "the fourth slide",
      caption: (
        <SliderCaptionMySoftwareApps>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Another Method
            </Typography>
            <Typography>
              this caption uses the SliderCaptionMySoftwareApps component with
              children instead of props. use this method to embed{" "}
              <Link
                href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a"
                target="_blank"
                rel="noopener"
              >
                links
              </Link>
              .
            </Typography>
          </Grid>
        </SliderCaptionMySoftwareApps>
      ),
      lightBoxCaption: (
        <LightBoxCaptionMySoftwareApps
          headline="LightBox Caption 4"
          text="fullscreen caption text. this uses the LightBoxCaptionMySoftwareApps component, and is shown when the slider enters fullscreen view (commonly referred to as a lightbox). this area will scroll when its content overflows. there can be lots and lots and lots and lots and lots of content in here. extra content from Proteus: Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy. Bald he was and a millionaire, maestro di color che sanno. Limit of the diaphane in. Why in? Diaphane, adiaphane. If you can put your five fingers through it, it is a gate, if not a door. Shut your eyes and see."
        />
      ),
    },
    {
      img: "https://picsum.photos/id/1016/800/600",
      imgAlt: "the fifth slide",
      caption: (
        <SliderCaptionMySoftwareApps
          headline="slide 5"
          text="this is a caption for slide 5"
        />
      ),
      lightBoxCaption: (
        <LightBoxCaptionMySoftwareApps
          headline="LightBox Caption 5"
          text="fullscreen caption text. this uses the LightBoxCaptionMySoftwareApps component, and is shown when the slider enters fullscreen view (commonly referred to as a lightbox). this area will scroll when its content overflows. there can be lots and lots and lots and lots and lots of content in here. extra content from Proteus: Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy. Bald he was and a millionaire, maestro di color che sanno. Limit of the diaphane in. Why in? Diaphane, adiaphane. If you can put your five fingers through it, it is a gate, if not a door. Shut your eyes and see."
        />
      ),
    },
    {
      img: "https://picsum.photos/id/1018/800/600",
      imgAlt: "the sixth slide",
      caption: (
        <SliderCaptionMySoftwareApps
          headline="slide 6"
          text="this is a caption for slide 6"
        />
      ),
      lightBoxCaption: (
        <LightBoxCaptionMySoftwareApps
          headline="LightBox Caption 6"
          text="fullscreen caption text. this uses the LightBoxCaption component, and is shown when the slider enters fullscreen view (commonly referred to as a lightbox). this area will scroll when its content overflows. there can be lots and lots and lots and lots and lots of content in here. extra content from Proteus: Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy. Bald he was and a millionaire, maestro di color che sanno. Limit of the diaphane in. Why in? Diaphane, adiaphane. If you can put your five fingers through it, it is a gate, if not a door. Shut your eyes and see."
        />
      ),
    },
  ];

  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.sliderContainer}>
        {/* set the autoPlay prop to turn on infinite repeat auto play */}
        {/* <GSAPSliderMySoftwareApps slides={slides} autoPlay autoPlayDelay={5} /> */}
        <GSAPSliderMySoftwareApps slides={slides} />
      </div>
      <SectionTwoMySoftwareApps />
      <SectionThreeMySoftwareApps />
    </div>
  );
}

export default SliderMySoftwareAppsPage;

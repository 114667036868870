import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SectionThreeBlockMySoftwareApps from "./SectionThreeBlockMySoftwareApps";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3em 0",
    backgroundColor: theme.palette.colors.darkBlue,
  },
}));

function SectionThreeMySoftwareApps() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <SectionThreeBlockMySoftwareApps heading="Gatsby and Vercel" />
      <SectionThreeBlockMySoftwareApps
        heading="Brewing Code"
        imageSrc="https://picsum.photos/id/8/400/300"
      />
      <SectionThreeBlockMySoftwareApps
        heading="VS Code Editor"
        text="much less text. keep the text content similar for best alignment. we're using alignItems='center' to vertically align the text side of the grid with the image."
      />
    </Grid>
  );
}

export default SectionThreeMySoftwareApps;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import skillsListJSON from "../../data/skills-list.json";
import Header from "./SkillsPageHeader";
import SkillBox from "./SkillBox";
import ScrollToTop from "../../ScrollToTop";
import ScrollToBottom from "../../ScrollToBottom";
import SkillSectionHeader from "./SkillSectionHeader";
import gsap from "gsap";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    visibility: "hidden",
  },
  contentContainer: {
    marginBottom: "250px",
  },
  contentMessage: {
    width: "100%",
    position: "absolute",
  },
  bottomOfPage: {
    width: "100%",
    height: "33vh",
    visibility: "hidden",
  },
  skillSectionContainer: {
    position: "relative",
    marginBottom: theme.spacing(5),
  },
  skillSectionGrid: {
    margin: 0,
  },
}));

function SkillsPage(props) {
  const sortedSkillsList = sortSkillsListData(skillsListJSON);
  const pageRef = React.useRef();
  const containerRef = React.useRef();
  const bottomOfPageRef = React.useRef();
  let skillSectionRefs = [];
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = "manual";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const page = pageRef.current;
    gsap.set(page, { autoAlpha: 1 });
    // console.dir(pageRef);
    // console.dir(page);
    // gsap.from(page, { scale: 0, duration: 5, transformOrigin: "50% 50%" });
    // console.dir(sortedSkillsList);
  }, []);

  const classes = useStyles();

  return (
    <div ref={pageRef} className={classes.root}>
      <Header
        skillSectionRefs={skillSectionRefs}
        sortedSkillsList={sortedSkillsList}
      />
      <div className={classes.contentContainer}>
        {sortedSkillsList.skillCategories.map((category, i) => {
          const skills = sortedSkillsList.sortedSkills[i];
          const newRef = React.createRef();
          skillSectionRefs.push(newRef);
          return (
            <div
              ref={containerRef}
              className={classes.skillSectionContainer}
              key={category + "-" + i}
            >
              <SkillSectionHeader
                ref={newRef}
                text={category}
                headerIndex={i}
              />
              <Container maxWidth="lg" ref={newRef}>
                <Grid
                  className={classes.skillSectionGrid}
                  item
                  container
                  xs={12}
                  spacing={3}
                >
                  {skills.map((skill, i) => {
                    const icon = skill["usesfontawesome"]
                      ? [skill["icon prefix"], skill["icon name"]]
                      : null;
                    const mediumColumns = skill["medium columns"]
                      ? skill["medium columns"]
                      : 12;
                    const hasLink = skill["has link"] ? true : false;
                    const imageOne =
                      skill["image1forcontentblock"] !== ""
                        ? skill["image1forcontentblock"]
                        : false;
                    const imageTwo =
                      skill["image2forcontentblock"] !== ""
                        ? skill["image2forcontentblock"]
                        : false;
                    return (
                      <SkillBox
                        key={category + "-skill-" + i}
                        header={skill["skill"]}
                        icon={icon}
                        mediumColumns={mediumColumns}
                        link={hasLink}
                        externalLink={skill["external link"]}
                        internalLink={skill["link path"]}
                        imageOne={imageOne}
                        imageTwo={imageTwo}
                      />
                    );
                  })}
                </Grid>
              </Container>
            </div>
          );
        })}
      </div>
      <div ref={bottomOfPageRef} className={classes.bottomOfPage}></div>
      <ScrollToTop />
      <ScrollToBottom ref={bottomOfPageRef} />
    </div>
  );
}

function sortSkillsListData(data) {
  let currentCategory = data[0]["skill category"];
  let skillCategories = [currentCategory];
  let sortedSkills = [];
  let tempArray = [];
  data.forEach((skill) => {
    const category = skill["skill category"];
    if (category !== currentCategory) {
      skillCategories.push(category);
      currentCategory = category;
      sortedSkills.push(tempArray);
      tempArray = [];
      tempArray.push(skill);
    } else {
      tempArray.push(skill);
    }
  });
  sortedSkills.push(tempArray);

  const sortedData = {
    skillCategories: skillCategories,
    sortedSkills: sortedSkills,
  };
  return sortedData;
}

export default SkillsPage;

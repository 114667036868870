import React from "react";
import { Link } from "react-router-dom";

function MyLink({ path, currentUser, linkText, protectThis, color }) {
  // console.warn(">>>> WHAT ARE WE PASSING IN TO MyLink() -->");
  // console.log(`current user: ${currentUser}`);
  // console.log(`linkText: ${linkText}`);
  // console.log(`protectThis: ${protectThis}`);
  // console.log(`path: ${path}`);
  // console.log(`color: ${color}`);
  // console.log(`linkClass: ${linkClass}`);
  // console.log(
  //   "NOW the if statements for dynamically rendering the Links are next."
  // );

  let linkColor = color === "primary" ? "white" : "orange";

  if (currentUser && protectThis) {
    // console.log(
    //   `protectedlink: currentUser is true and protectThis is true for link: ${linkText}`
    // );
    return (
      <>
        <Link style={{ color: linkColor }} to={path} key={path}>
          {linkText}
        </Link>
      </>
    );
  }

  if (!protectThis) {
    return (
      <>
        <Link to={path} key={path} style={{ color: linkColor }}>
          {linkText}
        </Link>
      </>
    );
  }

  if (!currentUser && protectThis) {
    // console.log(
    //   `uglylink: currentUser is false and protectThis is true for link: ${linkText}`
    // );
    return (
      <>
        <Link to="/welcome" style={{ color: "#AAAAAA" }}>
          {linkText}
        </Link>
      </>
    );
  }

  // console.log(
  //   `normallink: currentUser is false and protectThis is false for link: ${linkText}`
  // );
}

export default MyLink;

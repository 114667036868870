import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import { ReactComponent as SVG } from "../../img/grid.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MouseHoverPopover from "../../MouseHoverPopover";
import * as Utilities from "../../animations/AnimationUtilities";

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    backgroundColor: theme.palette.colors.darkBlue,
    overflowX: "hidden",
  },
  contentContainer: {
    margin: "50px 0",
  },
  sectionHeader: {
    fontSize: "34px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  sectionText: {
    marginTop: theme.spacing(2),
  },
  underline: {
    textDecoration: "underline",
  },
  imgContainer: {
    textAlign: "center",
    position: "relative",
    height: "500px",
    width: "500px",
    [theme.breakpoints.only("sm")]: {
      maxHeight: "400px",
      maxWidth: "400px",
    },
    [theme.breakpoints.only("xs")]: {
      maxHeight: "250px",
      maxWidth: "250px",
    },
  },
  svg: {
    position: "absolute",
    width: "100%",
    height: "auto",
  },
  textContainer: {
    textAlign: "left",
  },
}));

function WhatAboutFlexbox(props) {
  const contentWrapperRef = React.useRef();
  const svgRef = React.useRef();
  const headlineRef = React.useRef();
  const textBlockOneRef = React.useRef();
  const textBlockTwoRef = React.useRef();
  const mouseHoverPopoverRef = React.useRef();

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    const svg = svgRef.current;
    const headline = headlineRef.current;
    const textBlockOne = textBlockOneRef.current;
    const textBlockTwo = textBlockTwoRef.current;
    const mouseHoverPopover = mouseHoverPopoverRef.current;
    Utilities.centerElement(svg);

    // hide elements and reveal container
    const mainTL = gsap.timeline();
    mainTL.set(contentWrapper, { autoAlpha: 1 });

    // animate
    // svg
    const svgStartTween = { x: -100, autoAlpha: 0 };
    const svgEndTween = { x: 0, autoAlpha: 1 };
    makeScrollEffect(svg, svgStartTween, svgEndTween, "top 45%");
    // headline
    const headlineStartTween = { x: 100, autoAlpha: 0 };
    const headlineEndTween = { x: 0, autoAlpha: 1 };
    makeScrollEffect(headline, headlineStartTween, headlineEndTween);
    // text blocks
    const textBlockStartTween = { y: 30, autoAlpha: 0 };
    const textBlockEndTween = { y: 0, autoAlpha: 1 };
    makeScrollEffect(
      textBlockOne,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
    makeScrollEffect(
      textBlockTwo,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
    // mouse hover popover
    makeScrollEffect(
      mouseHoverPopover,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentWrapperRef} className={classes.contentWrapper}>
      <Container maxWidth="lg">
        <Grid
          className={classes.contentContainer}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.imgContainer} item xs={11} md={5}>
            <SVG ref={svgRef} className={classes.svg} />
          </Grid>
          <Grid className={classes.textContainer} item xs={11} md={7}>
            <Typography ref={headlineRef} className={classes.sectionHeader}>
              What about Flexbox?
            </Typography>
            <Typography ref={textBlockOneRef} className={classes.sectionText}>
              The Flexible Box Module, usually referred to as flexbox, was
              designed as a one-dimensional layout model, and as a method that
              could offer space distribution between items in an interface and
              powerful alignment capabilities. This article gives an outline of
              the main features of flexbox, which we will be exploring in more
              detail in the rest of these guides.
            </Typography>
            <Typography ref={textBlockTwoRef} className={classes.sectionText}>
              When we describe flexbox as being one dimensional we are
              describing the fact that flexbox deals with layout in one
              dimension at a time either as a row or as a column. This can be
              contrasted with the two-dimensional model of CSS Grid Layout,
              which controls columns and rows together.
            </Typography>
            <div
              ref={mouseHoverPopoverRef}
              className={classes.mouseHoverPopover}
            >
              <Link
                href="https://www.microsoft.com/devops"
                color="secondary"
                target="_blank"
                rel="noopener"
              >
                <MouseHoverPopover
                  anchorText="Popover - Hover over me to see effects"
                  title="The Popover Title"
                  content="Some content to show when mouse moves over this area! You can click or tap this to head over to Microsoft's Azure Devops web site."
                />
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function makeScrollEffect(element, startTween, endTween, endPosition) {
  const endPos = endPosition ? endPosition : "top 60%";
  const tl = gsap.timeline();
  tl.set(element, startTween);
  tl.to(element, endTween);
  ScrollTrigger.create({
    animation: tl,
    trigger: element,
    start: "top 80%",
    end: endPos,
    scrub: 0.66,
  });
}

export default WhatAboutFlexbox;

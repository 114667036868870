import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    "& a": {
      color: theme.palette.colors.orange,
    },
  },
  headline: {},
  text: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

function SliderCaptionYoutube(props) {
  const headline = props.headline ? props.headline : false;
  const text = props.text ? props.text : false;
  const children = props.children ? props.children : false;

  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Grid className={classes.root} container justifyContent="center">
      {headline && (
        <Grid container item xs={12} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              className={classes.headline}
              variant="h4"
              align="center"
            >
              {headline}
            </Typography>
          </Grid>
        </Grid>
      )}
      {text && (
        <Grid container item xs={12} justifyContent="center">
          <Grid item xs={11} sm={10} md={8} lg={6}>
            <Typography className={classes.text}>{text}</Typography>
          </Grid>
        </Grid>
      )}
      {children && (
        <Grid container item xs={12} justifyContent="center">
          {children}
        </Grid>
      )}
    </Grid>
  );
}

export default SliderCaptionYoutube;

import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    backgroundColor: "##82a3d6",
    padding: theme.spacing(2),
    maxWidth: "400px",
  },
  anchorText: {
    fontSize: theme.typography.body1.fontSize,
  },
  title: {
    color: theme.palette.colors.black,
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  content: {
    color: theme.palette.colors.black,
    fontSize: "14px",
    fontWeight: 400,
  },
}));

function MouseOverPopover(props) {
  const anchorText = props.anchorText
    ? props.anchorText
    : "Hover to see a Popover";

  const title = props.title ? props.title : false;

  const popoverContent = props.content ? (
    props.content
  ) : (
    <Typography>Popover Content</Typography>
  );

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <span
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.anchorText}
      >
        {anchorText}
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock={true}
      >
        {title && <Typography className={classes.title}>{title}</Typography>}
        <Typography className={classes.content}>{popoverContent}</Typography>
      </Popover>
    </div>
  );
}

export default MouseOverPopover;

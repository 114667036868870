import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Link, Box, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { CurrentUserContext } from "./userContext";
import DevOpsIcon from "./animations/DevOpsIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    height: theme.sizes.footerHeight,
    width: "100vw",
    backgroundColor: theme.palette.background.footer,
    borderTop: "1px solid " + theme.palette.colors.white,
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
  },
  devOpsIconContainer: {
    textAlign: "center",
    position: "relative",
  },
  devOpsIconContainerLoggedIn: {
    textAlign: "center",
    position: "relative",
    marginTop: "18px",
    [theme.breakpoints.only("md")]: {
      marginTop: "18px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "1px",
    },
  },
  footerLinkIcon: {
    marginRight: theme.spacing(0.5),
  },
  footerLinkText: {
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
      paddingRight: "1em",
    },
  },
}));

// eslint-disable-next-line
const footerLinks = [
  { text: "Site-Map", href: "https://www.scottshelpdesk.com/templates/svgfiles/ScottsProjectDiagramFinal10312022.html" },
  { text: "Tech. Research", href: "https://www.scottshelpdesk.com/react/ScottsUseEffect/" },
  { text: "DevOps Info.", href: "#theFlowchart" },
  { text: "YouTube Tutorials", href: "https://www.youtube.com/channel/UCMSUkhR0QTdLVPwEOzuRy8w" },
  {
    text: "Blog Articles",
    href: "https://scottsblog2.netlify.app",
  },
];

function Footer(props) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // eslint-disable-next-line
  const { currentUser, handleLogout } = React.useContext(CurrentUserContext);

  useEffect(() => {}, []);

  // eslint-disable-next-line
  const footerLinksShorterText = [
    { text: "Site-Map", href: "https://www.scottshelpdesk.com/templates/svgfiles/ScottsProjectDiagramFinal10312022.html" },
    { text: "Tech. Research", href: "https://www.scottshelpdesk.com/react/ScottsUseEffect/" },
    { text: "DevOps Info.", href: "#theFlowchart" },
    { text: "YouTube Tutorials", href: "https://www.youtube.com/channel/UCMSUkhR0QTdLVPwEOzuRy8w" },
    {
      text: "My Blog",
      href: "https://scottsblog2.netlify.app",
    },
    {
      text: `Hi, ${currentUser ? currentUser.email : ""}`,
      href: "/userprofilepage",
      hideThis: true,
    },
  ];

  const classes = useStyles();

  const linkLogic = (link, currentUser) => {
    if (link.hideThis && currentUser) {
      return (
        <Link
          href={link.href}
          target="_blank"
          rel="noopener"
          color="secondary"
        >
          <FontAwesomeIcon
            icon={faListAlt}
            className={classes.footerLinkIcon}
          />
          {link.text}
        </Link>
      );
    } else if (!link.hideThis) {
      return (
        <Link
          href={link.href}
          target="_blank"
          rel="noopener"
          color="secondary"
        >
          <FontAwesomeIcon
            icon={faListAlt}
            className={classes.footerLinkIcon}
          />
          {link.text}
        </Link>
      );
    } else if (link.hideThis && !currentUser) {
      return <></>;
    }
  };

  return (
    <Grid
      container
      className={classes.container}
      alignContent="center"
      justifyContent="space-around"
    >
      <Grid
        item
        xs={2}
        className={
          currentUser
            ? classes.devOpsIconContainerLoggedIn
            : classes.devOpsIconContainer
        }
      >
        <DevOpsIcon />
      </Grid>

      {footerLinksShorterText.map((link, i) => {
        const responsiveLinkVisibility =
          i === 0 ? { xs: "block" } : { xs: "none", md: "block" };
        const numberOfColumns = smallScreen ? 6 : 2;
        return (
          <Grid key={"element-key-" + link.text} item xs={numberOfColumns}>
            <Box
              element="span"
              className={classes.footerLinkText}
              align="center"
              display={responsiveLinkVisibility}
            >
              {linkLogic(link, currentUser)}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Footer;

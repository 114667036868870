import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  headerImage: {
    textAlign: "center",
  },
}));

function BlogsiteHeader(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={11}>
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography variant="h1" className={classes.headerH1} align="center">
            Articles from Scott's Blogsite CMS
          </Typography>
          <Typography className={classes.subHeader}>
            <i>
              Scroll down to see the blogging articles Scott has written on his
              blog site. In the BlogsiteGrid I need to show a list of links to
              all the articles.
            </i>
            You can find articles under different categories such as: ...{" "}
            <i>GET LIST OF CATEGORIES FROM DATABASE ... </i>{" "}
            <Link href="/home" rel="noopener" color="secondary">
              Go to Home Page
            </Link>
            .
          </Typography>
        </Grid>
        <Grid className={classes.headerRight} item xs={12} md={5}>
          <div className={classes.headerImage}>
            <img
              src="https://picsum.photos/400/300?grayscale"
              alt="lorem picsum"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BlogsiteHeader;

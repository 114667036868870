import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Paper, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.colors.lightBlue,
    overflow: "hidden",
  },
  gridContainer: {
    padding: "50px 0 60px",
  },
  gridItem: {
    height: "50px",
    lineHeight: "50px",
    textAlign: "center",
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.darkBlue,
    margin: theme.spacing(1),
  },
  gridHeadline: {
    marginBottom: "0.6em",
    color: theme.palette.colors.darkBlue,
  },
  gridSubHeadline: {
    marginBottom: "0.6em",
    color: theme.palette.colors.darkBlue,
  },
  gridLink: {
    color: theme.palette.colors.white,
  },
}));

function TemplateGrid(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          className={classes.gridContainer}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.gridHeadline}
              variant="h2"
              align="center"
            >
              Example Grid
            </Typography>
            <Typography
              className={classes.gridSubHeadline}
              variant="h6"
              align="center"
            >
              This is an example of the{" "}
              <Link
                href="https://v4.mui.com/components/grid/#grid"
                target="_blank"
                rel="noopener"
                className={classes.gridLink}
              >
                Material UI Grid
              </Link>{" "}
              using{" "}
              <Link
                href="https://v4.mui.com/components/paper/#paper"
                target="_blank"
                rel="noopener"
                className={classes.gridLink}
              >
                MUI Paper
              </Link>{" "}
              components.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.gridItem}>Paper Default</Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} className={classes.gridItem}>
              Paper elevation = 0
            </Paper>
          </Grid>
          <Grid item xs={6} md={6}>
            <Paper className={classes.gridItem} elevation={3}>
              Paper elevation = 3
            </Paper>
          </Grid>
          <Grid item xs={6} md={6}>
            <Paper className={classes.gridItem} elevation={10}>
              Paper elevation = 10
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper variant="outlined" className={classes.gridItem}>
              outlined
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper variant="outlined" className={classes.gridItem}>
              outlined
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper square className={classes.gridItem}>
              square
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper square className={classes.gridItem}>
              square
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default TemplateGrid;

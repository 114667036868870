import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { CurrentUserContext } from "../userContext";

// Seeing an error like this
//   https://stackoverflow.com/questions/37269808/react-js-uncaught-in-promise-syntaxerror-unexpected-token-in-json-at-posit
// Google search for error
//  https://www.google.com/search?q=Uncaught+(in+promise)+SyntaxError%3A+Unexpected+token+%3C+in+JSON+at+position+0&oq=Uncaught+(in+promise)+SyntaxError%3A+Unexpected+token+%3C+in+JSON+at+position+0&aqs=chrome..69i57.240j0j15&sourceid=chrome&ie=UTF-8

function RequireAuth({ children, adminRoute }) {
  const { currentUser, authLoading, checkLogin } =
    React.useContext(CurrentUserContext);
  console.log(
    `Values of currentUser and authLoading: ${currentUser} and ${authLoading}`
  );

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line
  }, []);

  if (authLoading) {
    return <div>Loading...</div>;
  }

  // Redirect if we're on an admin route and not admin user
  if (!currentUser?.isAdmin && adminRoute && !authLoading) {
    console.log(
      "RequireAuth.js: We do not have a logged-in user that can access a protected Component/Page, so navigate to login page"
    );
    return <Navigate to="/login" />;
  }

  // Redirect if on protected route and not logged in
  if (!currentUser && !authLoading && adminRoute && !currentUser?.isAdmin) {
    console.log(
      "RequireAuth.js: We do not have a logged-in user that can access a protected Component/Page, so navigate to login page"
    );
    return <Navigate to="/login" />;
  }

  return children;
}

export default RequireAuth;

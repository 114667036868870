import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./TemplateHeader";
import FullScreenDiv from "./TemplateFullScreenDiv";
import TemplateGrid from "./TemplateGrid";
import TemplateProgrammaticGrid from "./TemplateProgrammaticGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px", // space for fixed footer
  },
}));

function TemplatePage(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <TemplateGrid />
      <FullScreenDiv />
      <TemplateProgrammaticGrid />
    </div>
  );
}

export default TemplatePage;

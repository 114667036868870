import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HomePageHeader from "./HomePageHeader";
import HomePageBlocksSectionOne from "./HomePageBlocksSectionOne";
import MyExperiencesWithDevOps from "./MyExperiencesWithDevOps";
import HomePageBlocksSectionTwo from "./HomePageBlocksSectionTwo";
import MoreConceptualDetails from "./MoreConceptualDetails";
import DevOpsInfinityLoop from "./DevOpsInfinityLoop";
import WhatAboutAgileDevelopment from "./WhatAboutAgileDevelopment";
import CICDProcessFlowchart from "./CICDProcessFlowchart";
import AzureDevopsProcessFlowchart from "./AzureDevopsProcessFlowchart";
import WhatAboutFlexbox from "./WhatAboutFlexbox";
import HomePageEnd from "./HomePageEnd";
import ScrollToTop from "../../ScrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.sizes.footerHeight,
  },
}));

function HomePage(props) {
  const pageRef = React.useRef();

  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div ref={pageRef} className={classes.root}>
      <HomePageHeader />
      <HomePageBlocksSectionOne />
      <MyExperiencesWithDevOps />
      <HomePageBlocksSectionTwo />
      <MoreConceptualDetails />
      <DevOpsInfinityLoop />
      <WhatAboutAgileDevelopment />
      <CICDProcessFlowchart />
      <AzureDevopsProcessFlowchart />
      <WhatAboutFlexbox />
      <HomePageEnd />
      <ScrollToTop />
    </div>
  );
}

export default HomePage;

import React from "react";
import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: theme.spacing(1),
    marginBottom: "50px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  imageContainer: {
    textAlign: "right",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
  },
  image: {
    maxHeight: "300px",
    maxWidth: "400px",
    width: "100%",
    height: "auto",
  },
  heading: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  text: {
    padding: theme.spacing(2),
  },
  buttonContainer: {},
  button: {
    "& .MuiButton-iconSizeMedium > *:first-child": {
      fontSize: "15px",
    },
  },
  italicize: {
    fontStyle: "italic",
  },
}));

function SectionThreeBlockMySoftwareApps(props) {
  const imageSrc = props.imageSrc
    ? props.imageSrc
    : "https://picsum.photos/id/6/400/300";
  const imageAlt = props.imageAlt
    ? props.imageAlt
    : "you should always set an alt";
  const heading = props.heading ? props.heading : "Heading";
  const text = props.text
    ? props.text
    : "Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy.";

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const flexDirection = smallScreen ? "row" : "row-reverse";

  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid
        className={classes.gridContainer}
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        direction={flexDirection}
      >
        <Grid className={classes.imageContainer} item xs={12} md={5}>
          <img className={classes.image} src={imageSrc} alt={imageAlt} />
        </Grid>
        <Grid className={classes.textContainer} item xs={12} md={7}>
          <Typography className={classes.heading} variant="h4">
            {heading}
          </Typography>
          <Typography className={classes.text} paragraph>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SectionThreeBlockMySoftwareApps;

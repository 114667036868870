import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gsap from "gsap";
import * as Utilities from "../../animations/AnimationUtilities";

const navigationBulletContainerHeight = "40px";
const captionOverlayBackgroundColor = "rgba(10,10,10,0.66)";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "relative",
    visibility: "hidden",
    overflow: "hidden",
    backgroundColor: "#111",
    zIndex: 10,
    scrollbarGutter: "stable",
    "& *": {
      boxSizing: "border-box",
    },
  },
  slidesContainer: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  slidesInner: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  slide: {
    position: "absolute",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        minHeight: "50vh",
        minWidth: "100vw",
        width: "auto",
      },
    },
  },
  caption: {
    position: "absolute",
    bottom: navigationBulletContainerHeight,
    width: "100%",
    padding: "1em",
    paddingBottom: "0.5em",
    backgroundColor: captionOverlayBackgroundColor,
    textAlign: "center",
    zIndex: 100,
  },
  lightBoxCaption: {
    padding: "1em 3px",
  },
  slideControlButton: {
    position: "absolute",
    height: "40px",
    width: "40px",
    padding: 0,
    zIndex: 200,
    opacity: 0.66,
    backgroundColor: "transparent !important",
    cursor: "pointer",
    "& svg": {
      width: "100%",
      height: "100%",
      opacity: 0.75,
      transitionProperty: "opacity",
      transitionDuration: "0.2s",
    },
    "& svg:hover": {
      opacity: 1,
    },
  },
  navigationBulletContainer: {
    position: "absolute",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    paddingBottom: "15px",
    height: navigationBulletContainerHeight,
    backgroundColor: captionOverlayBackgroundColor,
    zIndex: 100,
  },
  navigationBullet: {
    height: navigationBulletContainerHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    margin: "0 2px",
    cursor: "pointer",
    position: "relative",
    visibility: "hidden",
    "& div": {
      position: "absolute",
      height: "3px",
      width: "100%",
      backgroundColor: "#fff",
    },
  },
  fullScreenButton: {
    position: "absolute",
    height: "25px",
    width: "25px",
    bottom: "20px",
    right: "25px",
    backgroundColor: "transparent !important",
    zIndex: 200,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "30px",
      width: "30px",
      top: "20px",
    },
    "& svg": {
      height: "100%",
      width: "100%",
      opacity: 0.7,
      transitionProperty: "opacity",
      transitionDuration: "0.2s",
    },
    "& svg:hover": {
      opacity: 1,
    },
  },
}));

function GSAPSlider(props) {
  const sliderItems = props.slides;
  const autoPlayOn = props.autoPlay ? props.autoPlay : false;
  const autoPlayDelay = props.autoPlayDelay ? props.autoPlayDelay : 1.5;
  const slideDuration = props.slideDuration ? props.slideDuration : 0.3;

  const containerRef = React.useRef();
  const slidesContainerRef = React.useRef();
  const slidesInnerRef = React.useRef();
  const previousSlideButtonRef = React.useRef();
  const nextSlideButtonRef = React.useRef();
  const navigationBulletContainerRef = React.useRef();
  const fullScreenButtonRef = React.useRef();
  const enterFullScreenIconRef = React.useRef();
  const exitFullScreenIconRef = React.useRef();

  let fullScreenState = false;

  let slideRefs = [];
  let navigationBulletRefs = [];
  let captionRefs = [];
  let lightBoxCaptionRefs = [];

  // animation globals
  const slidesWrap = gsap.utils.wrap(0, sliderItems.length);
  let proxy = document.createElement("div");
  let wrapWidth = 0;
  const progressWrap = gsap.utils.wrap(0, 1);
  let slideAnimation = gsap.to({}, {});
  let slideWidth = 0;
  let currentSlide = 0;
  let animation, snapX, timer;
  let mouseOverContainer = false;

  function updateNavigationBullets(bulletIndex) {
    const bullets = navigationBulletRefs.map((bullet) => bullet.current);
    if (bullets[0] !== null) {
      gsap.set(bullets, { autoAlpha: 0.3 });
      gsap.to(bullets[bulletIndex], { autoAlpha: 0.9, duration: 0.1 });
    }
  }

  function animateSlides(direction) {
    if (autoPlayOn && timer && !mouseOverContainer) {
      timer.restart(true);
    }
    slideAnimation.kill();

    var x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);

    slideAnimation = gsap.to(proxy, {
      x: x,
      duration: slideDuration,
      onUpdate: updateProgress,
      onStart: () => {
        currentSlide = slidesWrap(currentSlide + direction * -1);
        updateNavigationBullets(currentSlide);
      },
    });
  }

  function updateProgress() {
    animation.progress(progressWrap(gsap.getProperty(proxy, "x") / wrapWidth));
  }

  function autoPlay() {
    animateSlides(-1);
  }

  function pauseAutoplayOnContainerMouseOver(event) {
    if (event.type === "mouseenter") {
      mouseOverContainer = true;
      if (timer) {
        timer.kill();
      }
    }
    if (event.type === "mouseleave") {
      mouseOverContainer = false;
      timer = gsap.delayedCall(autoPlayDelay, autoPlay);
    }
  }

  function resize(slides) {
    var norm = gsap.getProperty(proxy, "x") / wrapWidth || 0;
    const numSlides = slides.length;
    slideWidth = slides[0].offsetWidth;
    wrapWidth = slideWidth * numSlides;
    snapX = gsap.utils.snap(slideWidth);

    gsap.set(proxy, {
      x: norm * wrapWidth,
    });

    animateSlides(0);
    slideAnimation.progress(1);
  }

  function handleNavigationBulletClick(bulletIndex) {
    animateSlides(currentSlide - bulletIndex);
  }

  function handleFullScreenButtonClick() {
    if (fullScreenState) {
      exitFullScreen();
    } else {
      enterFullScreen();
    }
    fullScreenState = !fullScreenState;
    updateFullScreenButtonIcons();
    toggleCaptions();
  }

  function updateFullScreenButtonIcons() {
    const enterFullScreenIcon = enterFullScreenIconRef.current;
    const exitFullScreenIcon = exitFullScreenIconRef.current;

    const iconToShow = fullScreenState
      ? exitFullScreenIcon
      : enterFullScreenIcon;

    gsap.set([enterFullScreenIcon, exitFullScreenIcon], { display: "none" });
    gsap.to(iconToShow, { display: "initial", duration: 0.2 });
  }

  function enterFullScreen() {
    const container = containerRef.current;
    const slidesInner = slidesInnerRef.current;
    const navigationButtons = [
      previousSlideButtonRef.current,
      nextSlideButtonRef.current,
    ];
    const navigationBulletContainer = navigationBulletContainerRef.current;
    const fullScreenButton = fullScreenButtonRef.current;
    const duration = 0.01;
    const tl = gsap.timeline();
    tl.set([document.querySelector("body"), document.querySelector("html")], {
      overflow: "hidden",
    });
    // responsive query
    const smallScreen = isSmallScreen();
    tl.add("start");
    tl.to(
      container,
      {
        position: "fixed",
        height: () => (smallScreen ? "104vh" : "100vh"),
        width: "100vw",
        zIndex: 10000,
        duration: duration,
        overflow: "visible",
      },
      "start"
    );
    // responsive image placement
    tl.to(
      slidesInner.querySelectorAll("img"),
      {
        top: () => (smallScreen ? 0 : "initial"),
        duration: duration,
      },
      "start"
    );

    // responsive fullscreen button placement
    tl.to(
      fullScreenButton,
      {
        top: () => (smallScreen ? "40px" : "initial"),
        duration: duration,
      },
      "start"
    );

    // responsive navigation buttons placement
    tl.to(
      navigationButtons,
      {
        top: () => (smallScreen ? "30%" : "initial"),
        duration: duration,
      },
      "start"
    );

    // responsive navigation bullet placement
    tl.to(
      navigationBulletContainer,
      {
        paddingBottom: () => (smallScreen ? "25px" : "initial"),
        duration: duration,
      },
      "start"
    );

    // exit full screen on escape key press
    document.addEventListener("keydown", handleEscapeKeyPress);
  }

  function exitFullScreen() {
    const container = containerRef.current;
    const slidesInner = slidesInnerRef.current;
    const fullScreenButton = fullScreenButtonRef.current;
    const navigationButtons = [
      previousSlideButtonRef.current,
      nextSlideButtonRef.current,
    ];
    const navigationBulletContainer = navigationBulletContainerRef.current;
    const smallScreen = isSmallScreen();
    const duration = 0.01;
    const tl = gsap.timeline();
    tl.set([document.querySelector("body"), document.querySelector("html")], {
      overflowY: "initial",
      overflowX: "hidden",
    });
    tl.add("start");
    tl.to(
      container,
      {
        position: "relative",
        height: "100%",
        width: "100%",
        zIndex: "initial",
        overflow: "hidden",
        duration: duration,
      },
      "start"
    );
    // reset responsive image position
    tl.to(
      slidesInner.querySelectorAll("img"),
      { top: "initial", duration: duration },
      "start"
    );

    // responsive fullscreen button placement
    tl.to(
      fullScreenButton,
      {
        top: () => (smallScreen ? "20px" : "initial"),
        duration: duration,
      },
      "start"
    );

    // responsive navigation buttons placement
    tl.to(
      navigationButtons,
      {
        top: "50%",
        duration: duration,
      },
      "start"
    );

    // responsive navigation bullet placement
    tl.to(
      navigationBulletContainer,
      {
        paddingBottom: "initial",
        duration: duration,
      },
      "start"
    );
  }

  function handleEscapeKeyPress(event) {
    if (fullScreenState === true && event.key === "Escape") {
      handleFullScreenButtonClick();
      document.removeEventListener("keydown", handleEscapeKeyPress);
    }
  }

  function toggleCaptions() {
    let captions = captionRefs.map((ref) => ref.current);
    let lightBoxCaptions = lightBoxCaptionRefs.map((ref) => ref.current);

    const captionsToShow = fullScreenState ? lightBoxCaptions : captions;
    const captionsToHide = fullScreenState ? captions : lightBoxCaptions;

    gsap.set(captionsToHide, { display: "none" });
    gsap.set(captionsToShow, { display: "initial" });
  }

  function isSmallScreen() {
    return window.innerWidth < 960 ? true : false;
  }

  useEffect(() => {
    // containers
    const container = containerRef.current;
    Utilities.centerElement(container);
    gsap.to(container, { autoAlpha: 1, duration: 0.2 });
    const slidesContainer = slidesContainerRef.current;
    if (autoPlayOn) {
      slidesContainer.onmouseenter = slidesContainer.onmouseleave =
        pauseAutoplayOnContainerMouseOver;
    }

    // controls
    const previousSlideButton = previousSlideButtonRef.current;
    const nextSlideButton = nextSlideButtonRef.current;
    Utilities.centerElement(previousSlideButton);
    Utilities.centerElement(nextSlideButton);
    gsap.set(previousSlideButton, {
      autoAlpha: 1,
      left: "10px",
      xPercent: 0,
    });
    gsap.set(nextSlideButton, {
      autoAlpha: 1,
      left: "initial",
      right: "10px",
      xPercent: 0,
    });
    previousSlideButton.addEventListener("click", function () {
      animateSlides(1);
    });
    nextSlideButton.addEventListener("click", function () {
      animateSlides(-1);
    });

    // slides
    const slides = slideRefs.map((slide) => slide.current);
    const numSlides = slides.length;
    gsap.set(slides, {
      xPercent: (i) => i * 100,
    });

    // captions
    toggleCaptions();

    // fullscreen toggle button
    const exitFullScreenIcon = exitFullScreenIconRef.current;
    gsap.set(exitFullScreenIcon, { display: "none" });

    // animation
    var wrap = gsap.utils.wrap(-100, (numSlides - 1) * 100);

    // eslint-disable-next-line
    animation = gsap.to(slides, {
      xPercent: "+=" + numSlides * 100,
      duration: 1,
      ease: "none",
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: wrap,
      },
    });

    // handle screen size
    window.addEventListener("resize", () => resize(slides));
    resize(slides);

    if (autoPlayOn) {
      // eslint-disable-next-line
      timer = gsap.delayedCall(autoPlayDelay, autoPlay);
    }

    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  return (
    <div ref={containerRef} className={classes.root}>
      <div ref={slidesContainerRef} className={classes.slidesContainer}>
        <div ref={slidesInnerRef} className={classes.slidesInner}>
          {sliderItems.map((slide, i) => {
            const newRef = React.createRef();
            slideRefs.push(newRef);
            const captionRef = React.createRef();
            captionRefs.push(captionRef);
            const lightBoxCaptionRef = React.createRef();
            lightBoxCaptionRefs.push(lightBoxCaptionRef);
            return (
              <div ref={newRef} className={classes.slide} key={"slide-" + i}>
                <img
                  className={classes.img}
                  src={slide.img}
                  alt={slide.imgAlt}
                />
                <div ref={captionRef} className={classes.caption}>
                  {slide.caption}
                </div>
                <div
                  ref={lightBoxCaptionRef}
                  className={`${classes.caption} ${classes.lightBoxCaption}`}
                >
                  {slide.lightBoxCaption}
                </div>
              </div>
            );
          })}
        </div>

        {/* slider controls */}
        <div
          ref={previousSlideButtonRef}
          className={classes.slideControlButton}
        >
          <FontAwesomeIcon icon={["fas", "angle-left"]} />
        </div>
        <div ref={nextSlideButtonRef} className={classes.slideControlButton}>
          <FontAwesomeIcon icon={["fas", "angle-right"]} />
        </div>

        {/* fullscreen button */}
        <div
          ref={fullScreenButtonRef}
          className={classes.fullScreenButton}
          onClick={handleFullScreenButtonClick}
        >
          <div ref={enterFullScreenIconRef}>
            <FontAwesomeIcon icon={["fas", "expand"]} />
          </div>
          <div ref={exitFullScreenIconRef}>
            <FontAwesomeIcon icon={["fas", "compress"]} />
          </div>
        </div>

        {/* navigation bullets */}
        <div
          ref={navigationBulletContainerRef}
          className={classes.navigationBulletContainer}
        >
          {sliderItems.map((slide, i) => {
            const newRef = React.createRef();
            navigationBulletRefs.push(newRef);
            return (
              <div
                ref={newRef}
                className={classes.navigationBullet}
                key={"navigation-bullet-" + i}
                onClick={() => handleNavigationBulletClick(i)}
              >
                <div></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default GSAPSlider;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import gsap from "gsap";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import * as Utilities from "./animations/AnimationUtilities";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const iconSize = "40px";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "65px",
    backgroundColor: theme.palette.colors.paleBlue,
    right: "15px",
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    cursor: "pointer",
    visibility: "hidden",
    zIndex: 1000,
  },
  iconContainer: {
    height: iconSize,
    width: iconSize,
    position: "absolute",
    "& svg": {
      height: iconSize,
      width: iconSize,
    },
    "& path": {
      color: theme.palette.colors.orange,
    },
  },
}));

function ScrollToTop(props) {
  const buttonRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    const button = buttonRef.current;
    const svg = svgRef.current;
    Utilities.centerElement(svg);

    const buttonRevealTL = gsap.timeline({
      onStart: () => {
        button.addEventListener("click", handleScrollTopButtonClick, false);
      },
    });
    buttonRevealTL.to(button, { autoAlpha: 1 });
    ScrollTrigger.create({
      animation: buttonRevealTL,
      trigger: document.querySelector("body"),
      start: "top -" + window.innerHeight * 1.5,
      end: "+=55px",
      toggleActions: "play none reverse",
    });
    // eslint-disable-next-line
  }, []);

  let scrollToTopTL;

  function handleScrollTopButtonClick() {
    var distance = window.pageYOffset;
    const speed = 4000; // increase to allow faster travel
    const minimumTravelTime = 0.25;
    const maxTravelTime = 1;
    const duration = Math.max(
      minimumTravelTime,
      Math.min(distance / speed, maxTravelTime)
    );
    scrollToTopTL = gsap.timeline({
      onStart: () => {
        document
          .querySelector("body")
          .addEventListener("click", bodyClickHandler, false);
      },
      onComplete: () => {
        document
          .querySelector("body")
          .removeEventListener("click", bodyClickHandler, false);
      },
    });
    scrollToTopTL.to(window, {
      duration: duration,
      scrollTo: 0,
      ease: "power1.inOut",
    });
  }

  function bodyClickHandler() {
    scrollToTopTL.paused(!scrollToTopTL.paused());
    document
      .querySelector("body")
      .removeEventListener("click", bodyClickHandler, false);
  }

  const classes = useStyles();

  return (
    <div ref={buttonRef} className={classes.root}>
      <span ref={svgRef} className={classes.iconContainer}>
        <FontAwesomeIcon icon={faAngleUp} />
      </span>
    </div>
  );
}

export default ScrollToTop;

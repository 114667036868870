import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAltV,
  faArrowsAlt,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import ContentBlock from "../../ContentBlock";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: "50px auto",
  },
}));

function HomePageBlocksSectionTwo(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.contentContainer}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Mobile First"
            icon={<FontAwesomeIcon icon={faMobile} />}
            content="Flexbox makes building responsive and mobile friendly layouts much easier"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="CSS Positioning"
            icon={<FontAwesomeIcon icon={faArrowsAlt} />}
            content="Flexbox can solve various css problems including how to position or dynamically resize elements on a page"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Layouts &amp; and Spacing"
            icon={<FontAwesomeIcon icon={faArrowsAltV} />}
            content="Handles lay outs, alignments, and space distributions among items in a container"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePageBlocksSectionTwo;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BlogsiteHeader from "./BlogsiteHeader";
import BlogsiteGrid from "./BlogsiteGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px", // space for fixed footer
  },
}));

function BlogsitePage(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BlogsiteHeader />
      <BlogsiteGrid />
    </div>
  );
}

export default BlogsitePage;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Link, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { CurrentUserContext } from "../../userContext";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  formFormat: { fontSize: "0.95rem", padding: "0.5em" },
  formFields: { margin: "0.2em", width: "175px" },
  formErrors: { color: "red", fontSize: "0.7rem" },
  formSubmitButton: {
    marginTop: "25px",
    marginLeft: "5px",
    marginBottom: "35px",
  },
  headerImage: {
    textAlign: "center",
  },
}));
///////////////////////////////////// NOTES ///////////////////////////////////////
// This is the Profile form to allow a user to update their Profile information.
//    - The user needs to be loggged inbefore attempting to use this page.
function ProfilePageForm(props) {
  toast.configure();

  // eslint-disable-next-line
  const { setCurrentUser, currentUser } = React.useContext(CurrentUserContext);

  console.log("value of currentUser: ");
  console.log({ currentUser });

  // const {
  //   user: { email, username },
  // } = props;

  // Custom hook to mange the entire User Profile form. useForm is custom hook for managing forms with ease. It takes optional arguments
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: currentUser.phone || "",
      allowSms: currentUser.allowSms || false,
      allowEmail: currentUser.allowEmail || false,
    },
  });

  // eslint-disable-next-line
  const navigate = useNavigate();

  const [shouldRedirect, setShouldRedirect] = useState(false);

  console.info(
    `INFO: In ProfilePageForm, shouldRedirect value: ${shouldRedirect}`
  );

  // updateUserProfile will call our Strapi backend (local or in production server) to attempt to update the users profile data
  const updateUserProfile = async (phone, allowSms, allowEmail) => {
    const token = localStorage.getItem("portfolio-token"); // need this before calling a protected strapi method

    const strapiURL =
      process.env.REACT_APP_STRAPIBACKEND_API_URLRENAMED ||
      "http://localhost:1337/api";
    const strapiPUTUSERMETHOD =
      process.env.REACT_APP_STRAPIBACKEND_API_PUTUSER_METHOD || "/users";
    const STRAPI_PUTUSER_URL =
      strapiURL + strapiPUTUSERMETHOD + `/${currentUser.id}`;
    console.info(`strapiURL : ${strapiURL} `);
    console.info(`strapiPUTUSERMETHOD : ${strapiPUTUSERMETHOD} `);
    console.info(`INFO: STRAPI_PUTUSER_URL IS  ${STRAPI_PUTUSER_URL}`);

    //const STRAPI_AUTH_URL = `https://scottblogsitebackend-staging.herokuapp.com/api/users/${currentUser.id}`;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // NOTES ABOUT STRAPI AUTH
    // A jwt token may be used for making permission-restricted API requests.
    // To make an API request as a user, place the jwt token into an Authorization header of the GET request.
    // A request without a token, will assume the strapi public role permissions by default.
    // Modify the permissions of each user's role in admin dashboard.
    // NOTE: Authentication failures return a 401 (unauthorized) error
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    try {
      const response = await fetch(STRAPI_PUTUSER_URL, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          phone: phone || null,
          allowEmail: allowEmail || false,
          allowSms: allowSms || false,
        }),
      });

      const jsonResponse = await response.json();

      // Lets see what strapi gave back to us. Could be that their password was too short, and we got a 400 status code
      console.info(
        `INFO: What happened with the fetch() call to auth, the HTTP status was: ${response.status}`
      );

      if (response.status === 400) {
        if (jsonResponse.error.message !== null) {
          console.log(`ERROR: error.message: ${jsonResponse.error.message}`);
          if (jsonResponse.error.message !== "") {
            toast.warning(
              `Error: Server issue occured cannot update profile, try again later: ${jsonResponse.error.message}`
            );
          }
        }
        return;

        //reset({ ...data }); // reset the form
      }

      if (response.status === 200) {
        console.info(
          "SUCCESS: called updateUserProfile! We got back a 200 response code. YaY! and the jsonResponse.jwt is: "
        );
        console.info(jsonResponse);
        toast.success("SUCCESS you updated your Profile!");
        setShouldRedirect(false);
        //navigate("/");
      } else {
        // bad things (toast things)
        toast.warning("Error: Server issue occured");
      }
    } catch (error) {
      //console.info("ERROR: catch statement of createUser");
      toast.warning("Catch Error: Server issue occured");
      //console.info(error);
      throw new Error(error); // TODO: What to do with this?
    }
  };

  useEffect(() => {
    //toast.success("Welcome!");
  }, []);

  const classes = useStyles();

  const onSubmit = async (data) => {
    // may update these three fields phone, allowSMS, allowEmail

    const { phone, allowSms, allowEmail } = data;

    await updateUserProfile(phone, allowSms, allowEmail); //Send users profile information to strapi
    return;
  };

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={11}>
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography variant="h1" className={classes.headerH1} align="center">
            User Profile Page
          </Typography>
          <Typography className={classes.subHeader}>
            Here is your <u>profile</u> information with our system.{" "}
            <i>
              You can let our system know if you want to receive an SMS message
              and/or an email message whenever we update the contents of this
              website.
            </i>{" "}
            <br />
            <br />
            From time to time we will be updating content on this website
            including the{" "}
            <Link href="/" rel="noopener" color="secondary">
              Home Page
            </Link>
            .
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formFormat}
          >
            <label>
              <select
                style={{ fontSize: "14pt", height: "25px", width: "210px" }}
                {...register("allowEmail", {
                  required: false,
                })}
              >
                <option value={true}>yes send me email</option>
                <option value={false}>do not send me email</option>
              </select>{" "}
              Get Email Messages?
            </label>
            <br />
            <label>
              <select
                style={{ fontSize: "14pt", height: "25px", width: "210px" }}
                {...register("allowSms", { required: false })}
              >
                <option value={true}>yes send me sms</option>
                <option value={false}>do not send me sms</option>
              </select>{" "}
              Get SMS Messages?
            </label>
            <br />
            <label>
              <input
                style={{ fontSize: "14pt", height: "25px", width: "120px" }}
                {...register("phone", { required: true, maxLength: 20 })}
              />{" "}
              SMS Number
            </label>
            {errors.phone && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <br />
            <br />
            <div>
              <Button
                className={classes.formSubmitButton}
                variant="contained"
                color="secondary"
                type="submit"
              >
                <Typography
                  style={{
                    textAlign: "right",
                    minWidth: "170px",
                    textTransform: "none",
                  }}
                >
                  Save Profile {` `}
                  <FontAwesomeIcon icon="fa-solid fa-address-card" />
                </Typography>
              </Button>
            </div>
          </form>
        </Grid>
        <Grid className={classes.headerRight} item xs={12} md={5}>
          <div className={classes.headerImage}>
            <img
              src="https://i.picsum.photos/id/2/400/300.jpg?grayscale&hmac=oV_ybjwxp4S7HHkw6y0u-oWRpcIlG9NWsJzZ1eGMCL4"
              alt="login"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProfilePageForm;

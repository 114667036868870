import React, { useState, createContext, useEffect } from "react";

export const CurrentUserContext = createContext({ user: {} });

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [authLoading, setAuthLoading] = useState(true);

  //   const checkLogin = async () => {
  //     const token = localStorage.getItem("portfolio-token");
  //     setAuthLoading(true);

  //     if (token) {
  //       // console.log("USERCONTEXT.JS (checkLogin): userContext -> we just read localstorage and the token is: ", token);
  //       console.log("--- We are here because we have a token ---");
  //       console.info(
  //         `USERCONTEXT.JS: checkLogin() -- about to fetch/GET to this endpoint: ${strapiURLRequest}`
  //       );
  //       // STRAPIBACKEND_API_URL: it is currently set to this: https://scottblogsitebackend-staging.herokuapp.com/api
  //       fetch(strapiURLRequest, {
  //         method: "GET",
  //         headers: {
  //           "Content-type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //         .then((res) => {
  //           if (res.status !== 200) {
  //             console.log("got a non 200 status code");
  //             setAuthLoading(false);
  //             setCurrentUser(null);
  //             throw new Error(res.statusText);
  //           } else {
  //             console.log("got a success return code?");
  //             return res.json();
  //           }
  //         })
  //         .then((data) => {
  //           console.info("user info we got from /api/users/me: ", data);
  //           setCurrentUser(data);
  //           setAuthLoading(false);
  //         });
  //     } else {
  //       console.info(
  //         ">>> in the final else statement, there was no token found in localstorage"
  //       );
  //       setAuthLoading(false);
  //       setCurrentUser(null);
  //     }
  //   };

  const checkLogin = () => {
    const token = localStorage.getItem("portfolio-token");
    setAuthLoading(true);

    const strapiURL =
      process.env.REACT_APP_STRAPIBACKEND_API_URL ||
      "http://localhost:1337/api";
    const strapiUsersMeMethod =
      process.env.REACT_APP_STRAPIBACKEND_API_ABOUTME_METHOD || "/users/me";
    const strapiURLRequest = strapiURL + strapiUsersMeMethod;
    //console.info(`strapiURL : ${strapiURL} `);
    //console.info(`strapiUsersMeMethod : ${strapiUsersMeMethod} `);
    //console.info(`strapiURLRequest IS  ${strapiURLRequest}`);

    if (token) {
      // console.log("USERCONTEXT.JS (checkLogin): userContext -> we just read localstorage and the token is: ", token);
      //console.log(
      //  "--- We are here because we have a token, which may or may not be a valid one (hacking attempt?) ---"
      //);
      //console.info(
      //  `USERCONTEXT.JS: checkLogin() -- about to fetch/GET to this endpoint: ${strapiURLRequest}`
      //);
      // CAN THE USER ACCESS A PROTECTED BACKEND API ENDPOINT?
      // STRAPIBACKEND_API_URL: it is currently set to this: https://scottblogsitebackend-staging.herokuapp.com/api
      // https://scottblogsitebackend-staging.herokuapp.com/api/users/me
      //fetch("https://scottblogsitebackend-staging.herokuapp.com/api/users/me", {
      fetch(strapiURLRequest, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          //42722 TODO how to handle this type of error in the UI?
          if (res.status !== 200) {
            console.log("got a non 200 status code and statusText is: ");
            console.info(res.statusText);
            console.log("contents of the response object: ");
            console.info(res);
            setAuthLoading(false);
            setCurrentUser(null);
            //throw new Error(res.statusText);
          } else {
            //console.log("in userContext.js: we got a success return code");
            return res.json();
          }
        })
        .then((data) => {
          //console.info("user info we got from /api/users/me: ", data);
          setCurrentUser(data);
          setAuthLoading(false);
        });
    } else {
      console.info(
        ">>> in the final else statement, there was no token found in localstorage"
      );
      setAuthLoading(false);
      setCurrentUser(null);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("portfolio-token");
    setCurrentUser(null);
  };

  const testData = "test data";

  // https://www.google.com/search?q=how+to+show+version+of+react+with+npm&oq=how+to+show+version+of+react+with+npm&aqs=chrome..69i57j33i160l2.6379j0j15&sourceid=chrome&ie=UTF-8
  // https://stackoverflow.com/questions/36994564/how-can-one-tell-the-version-of-react-running-at-runtime-in-the-browser
  // So to execute the code only during initial render/component mount you can pass an empty array
  // which means no dependencies specified when the effect should execute. Hence run during initial render and when the component is unmounted.
  useEffect(() => {
    checkLogin();
  }, []);

  const stateValues = {
    currentUser,
    setCurrentUser,
    checkLogin,
    authLoading,
    setAuthLoading,
    handleLogout,
    testData,
  };

  return (
    <CurrentUserContext.Provider value={stateValues}>
      {children}
    </CurrentUserContext.Provider>
  );
};

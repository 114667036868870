import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import Navbar from "./Navbar";
import SiteMap from "./SiteMap";
import Footer from "./Footer";
//import HomePage from "./pages/home/HomePage";
//import LoginPage from "./pages/login/LoginPage";
//import ResumePage from "./pages/resume/ResumePage";
import TemplatePage from "./pages/template/TemplatePage";
import RequireAuth from "./components/RequireAuth";
import SingleArticle from "./pages/blogsite/SingleArticle";

const useStyles = makeStyles((theme) => ({
  root: {
    visibility: "hidden",
    overflowX: "hidden",
  },
}));

function Content(props) {
  const pages = SiteMap();
  const contentRef = React.useRef();

  useEffect(() => {
    const content = contentRef.current;
    gsap.set(content, { autoAlpha: 1, duration: 0.1 });
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentRef} className={classes.root}>
      <Navbar />
      <Routes>
        {pages.map(
          ({ adminOnly, protectThis, path, Component = TemplatePage }) => {
            if (protectThis) {
              console.warn(`protected path is : ${path} `);
              return (
                <Route
                  path={path}
                  key={path}
                  element={
                    <RequireAuth adminRoute={adminOnly}>
                      <Component />
                    </RequireAuth>
                  }
                />
              );
            } else {
              console.log(`non-protected path is : ${path} `);
              return <Route path={path} key={path} element={<Component />} />;
            }
          }
        )}
        <Route path="/article/:slug" element={<SingleArticle />} />
        {/* <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="current-resume"
          element={
            <RequireAuth>
              <ResumePage />
            </RequireAuth>
          }
        /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default Content;

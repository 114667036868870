import HomePage from "./pages/home/HomePage";
import SkillsPage from "./pages/skills/SkillsPage";
import PassionProjects from "./pages/svg/SvgPage";
import KubernetesCluster from "./pages/template/TemplatePage";
import Testimonials from "./pages/template/TemplatePage";
import WebApps from "./pages/slider-template/SliderTemplatePage";
import YouTubeVideos from "./pages/myyoutubepage/SliderYoutubePage";
import TechTrends from "./pages/template/TemplatePage";
import ScottsResume from "./pages/resume/ResumePage";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/register/RegisterPage";
import ProfilePage from "./pages/user/ProfilePage";
import AdminToolsPage from "./pages/admin/AdminToolsPage";
import BlogsitePage from "./pages/blogsite/BlogsitePage";
import WelcomePage from "./pages/welcome/WelcomePage";
import SliderMySoftwareAppsPage from "./pages/mysoftwareapps/SliderMySoftwareAppsPage";
//import SvgPage from "./pages/svg/SvgPage";

//NOTE: for future reference: My Netlify automated DevOps complains (which is good thing) about "no-unused-vars".
//  2:27:25 PM: ./src/SiteMap.js
//  2:27:25 PM:   Line 10:8:  'SvgPage' is defined but never used  no-unused-vars

function SiteMap() {
  const pages = [
    // home
    {
      path: "/home",
      Component: HomePage,
      title: "ScottDevOps.com",
      linkText: "Home Page",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // blog
    {
      path: "https://scottsblog1.netlify.app",
      linkText: "Scott's Blog",
      externalLink: true,
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // skills
    {
      path: "/skills",
      Component: SkillsPage,
      title: "Skills Page",
      linkText: "My Skills List",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // My online e-store where I sell retro gear and electronics
    {
      path: "https://scottecommerce.vercel.app",
      linkText: "Scott's Online Store",
      externalLink: true,
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },

    // My software applications to check out and purchase too
    {
      path: "/mysoftwareapps",
      Component: SliderMySoftwareAppsPage,
      title: "My Software Apps",
      linkText: "My Software Apps",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // youtube videos
    {
      path: "/youtube-videos",
      Component: YouTubeVideos,
      title: "YouTube Videos",
      linkText: "My YouTube Videos",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // tech trends
    {
      path: "/tech-trends",
      Component: TechTrends,
      title: "Tech Trends",
      linkText: "Awesome Tech Topics",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // kubernetes cluster
    {
      path: "/kubernetes-cluster",
      Component: KubernetesCluster,
      title: "Kubernetes Cluster",
      linkText: "I Built a Kubernetes Cluster",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
    // register page
    {
      path: "/register",
      Component: RegisterPage,
      title: "Register Page",
      linkText: "Register an Account",
      hideThisinNavBar: true,
      protectThis: false,
      adminOnly: false,
    },
    // current resume
    {
      path: "/current-resume",
      Component: ScottsResume,
      title: "My Resume",
      linkText: "My Resume",
      hideThisinNavBar: false,
      protectThis: true,
      adminOnly: false,
    },
    // svg page
    //{
    //  path: "/svg",
    //  Component: SvgPage,
    //  title: "Svg Page",
    //  linkText: "Cool Svg Files",
    //},
    // testimonials
    {
      path: "/testimonials",
      Component: Testimonials,
      title: "Testimonials",
      linkText: "Testimonials",
      hideThisinNavBar: false,
      protectThis: true,
      adminOnly: false,
    },
    // passion projects
    {
      path: "/passion-projects",
      Component: PassionProjects,
      title: "Passion Projects",
      linkText: "Passion Projects",
      hideThisinNavBar: false,
      protectThis: true,
      adminOnly: false,
    },
    // web apps
    {
      path: "/web-apps",
      Component: WebApps,
      title: "Web Apps",
      linkText: "Web Apps I Built/Maintained",
      hideThisinNavBar: false,
      protectThis: true,
      adminOnly: false,
    },
    // login page
    {
      path: "/login",
      Component: LoginPage,
      title: "Login Page",
      linkText: "Login to System",
      hideThisinNavBar: true,
      protectThis: false,
      adminOnly: false,
    },
    // welcome page
    {
      path: "/welcome",
      Component: WelcomePage,
      title: "Welcome to ScottDevOps",
      linkText: "The Welcome Page",
      hideThisinNavBar: true,
      protectThis: false,
      adminOnly: false,
    },
    // Update Profile
    {
      path: "/userprofilepage",
      Component: ProfilePage,
      title: "Welcome to your User Profile Page",
      linkText: "User Profile Page",
      hideThisinNavBar: true,
      protectThis: true,
      adminOnly: false,
    },
    // Admin Tools Settings
    {
      path: "/admintoolsettings",
      Component: AdminToolsPage,
      title: "Welcome to Admin Tools Settings Page",
      linkText: "Admin Tools Page",
      hideThisinNavBar: true,
      protectThis: true,
      adminOnly: false,
    },
    // CMS Article Page
    {
      path: "/blogsitearticles",
      Component: BlogsitePage,
      title: "ScottDevOps.com",
      linkText: "Blog Article Page",
      hideThisinNavBar: true,
      protectThis: false,
      adminOnly: false,
    },

    // default
    {
      path: "*",
      Component: HomePage,
      title: "ScottDevOps.com",
      hideThisinNavBar: false,
      protectThis: false,
      adminOnly: false,
    },
  ];

  return pages;
}

export default SiteMap;

import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SectionThreeBlock from "./SectionThreeBlock";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3em 0",
    backgroundColor: theme.palette.colors.darkBlue,
  },
}));

function SectionThree() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <SectionThreeBlock heading="Heading" />
      <SectionThreeBlock
        heading="Heading 2"
        imageSrc="https://picsum.photos/id/8/400/300"
      />
      <SectionThreeBlock
        heading="Heading 3"
        text="much less text. keep the text content similar for best alignment. we're using alignItems='center' to vertically align the text side of the grid with the image."
      />
    </Grid>
  );
}

export default SectionThree;

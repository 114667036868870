import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleBySlug } from "../../utils/api-helpers";

const SingleArticle = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [articleData, setArticleData] = useState(null);

  useEffect(() => {
    console.log("in useEffect of SingleArticle before calling fetchArticle()");
    async function fetchArticle() {
      let article = await getArticleBySlug(slug);
      console.log(" just called getArticleBySlug(slug)");
      console.log({ article });
      setArticleData(article);
      setLoading(false);
    }

    fetchArticle();
    // eslint-disable-next-line
  }, []);
  return !loading && articleData ? (
    <div style={{ marginLeft: "3em", marginBottom: "5em" }}>
      <h1>{articleData.attributes.title}</h1>
      <img
        src={articleData.attributes.image.data.attributes.url}
        alt="article"
      />
      <h4>{articleData.attributes.description}</h4>
      <h5>Published Date: {articleData.attributes.published_date} </h5>
      <h5>
        <table style={{ width: "50%" }}>
          <tr>
            <td>
              Category: {articleData.attributes.category.data?.attributes.name}
            </td>
            <td>
              Author: {articleData.attributes.author.data?.attributes.name}
            </td>
          </tr>
          <tr>
            <td>{articleData.attributes.content}</td>
          </tr>
        </table>
      </h5>
      <hr></hr>
      <h1>More Pictures Here</h1>
      <img
        src="https://res.cloudinary.com/dzohugrrk/image/upload/v1648827191/20000_Thousand_Media_Items_And_More_239103f298.jpg"
        alt="happy"
      />
    </div>
  ) : (
    <div>Loading (or no article found)...</div>
  );
};

export default SingleArticle;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.colors.darkBlue,
    padding: theme.spacing(2),
    boxShadow: theme.palette.boxShadow,
    transition: "box-shadow 0.3s",
    visibility: "hidden",
    position: "relative",
    "&:hover": {
      boxShadow: "none",
    },
  },
  contentBoxHeader: {
    fontSize: "1.5em",
    fontWeight: 700,
    textAlign: "left",
  },
  contentBoxHeaderIcon: {
    marginRight: theme.spacing(2),
  },
  linkFlag: {
    position: "absolute",
    top: "10px",
    right: "10px",
    height: "15px",
    width: "15px",
    "& path": {
      fill: "#eee",
    },
  },
}));

function ContentBox(props) {
  const header = props.header ? props.header : false;
  const hasHeader = header === false ? false : true;
  const icon = props.icon ? props.icon : false;
  const hasIcon = icon === false ? false : true;
  const content = props.content ? props.content : false;
  const hasContent = content === false ? false : true;
  const isLink = props.link ? true : false;

  const boxRef = React.useRef();

  useEffect(() => {
    const box = boxRef.current;

    const mainTL = gsap.timeline();
    mainTL.set(box, { y: 30 });
    mainTL.to(box, { autoAlpha: 1, y: 0 });

    ScrollTrigger.create({
      animation: mainTL,
      trigger: box,
      start: "top 80%",
      end: "top 60%",
      scrub: 0.5,
    });
  }, []);

  const classes = useStyles();

  return (
    <Paper ref={boxRef} className={classes.root} elevation={0}>
      {hasHeader && (
        <Typography className={classes.contentBoxHeader} align="center">
          {hasIcon && (
            <span className={classes.contentBoxHeaderIcon}>{icon}</span>
          )}
          {header}
        </Typography>
      )}
      {hasContent && (
        <Typography className={classes.contentBoxContent}>{content}</Typography>
      )}
      {isLink && (
        <FontAwesomeIcon
          className={classes.linkFlag}
          icon={["fas", "external-link"]}
        />
      )}
    </Paper>
  );
}

export default ContentBox;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import gsap from "gsap";
import * as Utilities from "../../animations/AnimationUtilities";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.colors.lightBlue,
    overflow: "hidden",
  },
  gridContainer: {
    padding: "50px 0 60px",
  },
  gridItem: {
    height: "100px",
    lineHeight: "100px",
    textAlign: "center",
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.darkBlue,
    margin: theme.spacing(1),
  },
  gridHeadline: {
    marginBottom: "0.6em",
    color: theme.palette.colors.darkBlue,
  },
  gridSubHeadline: {
    marginBottom: "0.6em",
    color: theme.palette.colors.darkBlue,
  },
  gridLink: {
    color: theme.palette.colors.white,
  },
}));

function TemplateProgrammaticGrid(props) {
  // create an array of keys to print out the markup
  let elementKeys = [];
  const numberOfGridElements = 20;
  for (let i = 0; i < numberOfGridElements; i++) {
    // this value doesn't matter, but React wants it to be unique
    elementKeys.push({
      key: "element key - " + i,
      columns: gsap.utils.random([2, 3, 4, 5, 6]),
    });
  }
  // empty array to hold refs that we create in the render map function
  let elementRefs = [];

  useEffect(() => {
    // get elements from refs
    const elements = elementRefs.map((element) => element.current);

    // animate elements
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    tl.to(Utilities.sattoloShuffleCopy(elements), {
      autoAlpha: 0,
      stagger: 0.2,
      duration: 2,
    });
    tl.to(elements, { autoAlpha: 1, stagger: 0.1, duration: 0.66 }, "+=0.7");
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          className={classes.gridContainer}
          container
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.gridHeadline}
              variant="h3"
              align="center"
            >
              Programmatic Grid
            </Typography>
            <Typography className={classes.gridSubHeadline} variant="h6">
              This is an example of a Grid made from a map function. The Grid
              items contain a number to show their width in columns, or a dash
              to indicate "auto" layout, which will fill available space in a
              row.
            </Typography>
            <Typography className={classes.gridSubHeadline} variant="h6">
              The grid items are assigned refs during the map function, then
              those refs are used to animate the grid items in the useEffect
              hook.
            </Typography>
          </Grid>
          {elementKeys.map((element, i) => {
            // a ref for this element
            const newRef = React.createRef();
            elementRefs.push(newRef);
            // roll to use the element.columns property or leave no column, which sets to "auto" and will fill out the row
            const markup =
              (i + 1) % 2 === 0 || i === elementKeys.length - 1 ? (
                <Grid ref={newRef} item key={element.key} xs>
                  <div className={classes.gridItem}>-</div>
                </Grid>
              ) : (
                <Grid ref={newRef} item key={element.key} xs={element.columns}>
                  <div className={classes.gridItem}>{element.columns}</div>
                </Grid>
              );
            return markup;
          })}
        </Grid>
      </Container>
    </div>
  );
}

export default TemplateProgrammaticGrid;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Link, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { CurrentUserContext } from "../../userContext";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  formFormat: { fontSize: "0.95rem", padding: "0.5em" },
  formFields: { margin: "0.2em", width: "175px" },
  formErrors: { color: "red", fontSize: "0.7rem" },
  formSubmitButton: {
    marginTop: "25px",
    marginLeft: "5px",
    marginBottom: "35px",
  },
  headerImage: {
    textAlign: "center",
  },
}));
///////////////////////////////////// NOTES ///////////////////////////////////////
// 1. This is the Login form to allow a login to the strapi backend for Scotts Portfolio Site
// 2. We will get back a jwt token if all goes well and once we have that jwt,
//    we want to determine what routes are protected in the frontend,
//    so, based on the jwt existence (i.e. user was authenticated) then the user
//    will be allowed or blocked from using the routes in the front end
function WelcomePageForm(props) {
  toast.configure();

  const { setCurrentUser } = React.useContext(CurrentUserContext);

  // Custom hook to mange the entire register form. useForm is custom hook for managing forms with ease. It takes optional arguments
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  console.info(
    `INFO: In WelcomePageForm and here is shouldRedirect value: ${shouldRedirect}`
  );
  console.info(`INFO: value of is NODE_ENV: ${process.env.NODE_ENV}`);

  // loginUser will call our Strapi backend (local or in production server) to attempt a login, and get a jwt token
  const loginUser = async (identifier, password) => {
    const strapiURL =
      process.env.REACT_APP_STRAPIBACKEND_API_URL ||
      "http://localhost:1337/api";
    const strapiAUTHMETHOD =
      process.env.REACT_APP_STRAPIBACKEND_API_AUTH_METHOD || "/auth/local";
    const STRAPI_AUTH_URL = strapiURL + strapiAUTHMETHOD;
    console.info(`strapiURL : ${strapiURL} `);
    console.info(`strapiAUTHMETHOD : ${strapiAUTHMETHOD} `);
    console.info(`INFO: STRAPI_AUTH_URL IS  ${STRAPI_AUTH_URL}`);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // NOTES ABOUT STRAPI AUTH
    // A jwt token may be used for making permission-restricted API requests.
    // To make an API request as a user, place the jwt token into an Authorization header of the GET request.
    // A request without a token, will assume the public role permissions by default.
    // Modify the permissions of each user's role in admin dashboard. Authentication failures return a 401 (unauthorized) error
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    try {
      const response = await fetch(STRAPI_AUTH_URL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ identifier, password }),
      });

      const jsonResponse = await response.json();

      // Lets see what strapi gave back to us. Could be that their password was too short, and we got a 400 status code
      console.info(
        `INFO: What happened with the fetch() call to auth, the HTTP status was: ${response.status}`
      );

      if (response.status === 400) {
        if (jsonResponse.error.message !== null) {
          console.log(`ERROR: error.message: ${jsonResponse.error.message}`);
          if (jsonResponse.error.message !== "") {
            toast.warning(
              `Error: Server issue occured cannot login, try again later: ${jsonResponse.error.message}`
            );
          }
        }
        return;

        //reset({ ...data }); // reset the form
      }

      if (response.status === 200) {
        console.info(
          "SUCCESS: called loginUser() was sucessful! We got back a 200 response code. YaY! and the jsonResponse.jwt is: "
        );
        console.info(jsonResponse);
        localStorage.setItem("portfolio-token", jsonResponse.jwt); //TODO0 replace with cookies for starters, then maybe session only cookies?
        setCurrentUser(jsonResponse.user);
        toast.success("SUCCESS you logged into your account!");
        setShouldRedirect(true);
        navigate("/");
      } else {
        // bad things (toast things)
        toast.warning("Error: Server issue occured");
      }
    } catch (error) {
      //console.info("ERROR: catch statement of createUser");
      toast.warning("Catch Error: Server issue occured");
      //console.info(error);
      throw new Error(error); // TODO: What to do with this?
    }
  };

  useEffect(() => {
    //toast.success("Welcome!");
  }, []);

  const classes = useStyles();

  const onSubmit = async (data) => {
    // Also note that if we make these all required fields, this condition should never happen
    if (!data.email || !data.password) {
      toast.warning("Email or password information is missing.");
      return;
    }

    if (data.password === "") {
      console.info(
        "WARNING: Passwords cannot be blank. So we did not call the Login function, so lets return back, because data is no good"
      );
      toast.warning("Passwords did not match");
      return;
    }

    await loginUser(data.email, data.password); //Send identifier and password to strapi
    return;
  };

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={11}>
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography variant="h1" className={classes.headerH1} align="center">
            Welcome! to ScottDevOps.com
          </Typography>
          <Typography className={classes.subHeader}>
            This is the place to <u>login</u> to your account with our system.{" "}
            <i>
              For security purposes, you need to wait until an admin of this
              system reviews your account and may enable it. Check back later.
            </i>{" "}
            If you do not already have an account then head over to the{" "}
            <Link href="/register" rel="noopener" color="secondary">
              Register Page
            </Link>{" "}
            to create one.
            <br />
            <br />
            Also, after you fill out the Registration form, then please check
            your email inbox for a welcome message. You need to confirm your
            account/email address before it can be approved for use.{" "}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formFormat}
          >
            <label>
              <input
                className={classes.formFields}
                {...register("email", { required: true, maxLength: 35 })}
              />{" "}
              &lt; Email
            </label>
            {errors.email && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <br />
            <label>
              <input
                name="password1"
                type="password"
                autoComplete="off"
                className={classes.formFields}
                {...register("password", { required: true, maxLength: 16 })}
              />{" "}
              &lt; Password
            </label>
            {errors.password && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <div>
              <Button
                className={classes.formSubmitButton}
                variant="contained"
                color="secondary"
                type="submit"
              >
                <Typography
                  style={{
                    textAlign: "right",
                    minWidth: "170px",
                    textTransform: "none",
                  }}
                >
                  Login Now {` `}
                  <FontAwesomeIcon icon="fa-solid fa-address-card" />
                </Typography>
              </Button>
            </div>
          </form>
        </Grid>
        <Grid className={classes.headerRight} item xs={12} md={5}>
          <div className={classes.headerImage}>
            <img
              src="https://i.picsum.photos/id/2/400/300.jpg?grayscale&hmac=oV_ybjwxp4S7HHkw6y0u-oWRpcIlG9NWsJzZ1eGMCL4"
              alt="login"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WelcomePageForm;

import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SectionTwoBlock from "./SectionTwoBlock";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3em 0",
    backgroundColor: theme.palette.colors.lightBlue,
    color: theme.palette.colors.black,
  },
}));

function SectionTwo() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <SectionTwoBlock heading="heading 1" />
      <SectionTwoBlock imageSrc="https://picsum.photos/id/2/300/300" />
      <SectionTwoBlock text="much less text. keep the text content similar for best alignment. we're using 'flex-start' to keep the top of each cell aligned." />
    </Grid>
  );
}

export default SectionTwo;

import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

function MakeTheme() {
  const colors = {
    darkBlue: "#0151cc",
    paleBlue: "#3474e6",
    lightBlue: "#a1c3ff",
    transparentBlue: "rgba(0, 123, 255, 0.15)",
    transparentViolet: "rgba(86, 61, 124, 0.15)",
    orange: "#ffa500",
    lightOrange: "#ffc107",
    darkOrange: "#cc8400",
    darkGray: "#343a40",
    lightGray: "rgba(255,255,255,0.5)",
    white: "#fafafa",
    black: "#080808",
  };
  const boxShadow = "6px 8px 13px 2px rgb(0 0 0 / 94%)";
  const navbarHeight = "56px";

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
      h1: {
        fontWeight: 700,
      },
      headerH1: {
        fontSize: "3em",
      },
      h2: {
        fontWeight: 600,
      },
      h5: {
        fontSize: "2em",
        fontWeight: 700,
      },
    },
    palette: {
      colors: {
        darkBlue: colors.darkBlue,
        paleBlue: colors.paleBlue,
        lightBlue: colors.lightBlue,
        transparentBlue: colors.transparentBlue,
        transparentViolet: colors.transparentViolet,
        orange: colors.orange,
        lightOrange: colors.lightOrange,
        darkOrange: colors.darkOrange,
        darkGray: colors.darkGray,
        lightGray: colors.lightGray,
        white: colors.white,
        black: colors.black,
      },
      secondary: {
        main: colors.orange,
      },
      icon: {
        main: colors.lightGray,
      },
      background: {
        default: colors.lightBlue,
        paper: colors.lightBlue,
        navbar: colors.darkGray,
        siteNavigationMenu: colors.paleBlue,
        header: colors.darkBlue,
        footer: colors.paleBlue,
        box: colors.darkBlue,
      },
      text: {
        primary: colors.white,
        secondary: colors.black,
        headerH1Color: colors.orange,
      },
      links: {
        color: colors.orange,
        visited: colors.black,
      },
      boxShadow: boxShadow,
      animations: {},
    },
    sizes: {
      navbarHeight: navbarHeight,
      footerHeight: "50px",
    },
    mixins: {
      toolbar: {
        "@media (min-width:0px)": {
          minHeight: navbarHeight,
        },
      },
    },
  });

  return theme;
}

export default MakeTheme;
